@import 'styles/variables';

.MainMenu {
  background: $dark-blue;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem 0rem 0rem;
  height: $top-bar-height;
  top: 0;
  z-index: 10000;
  position: sticky;

  .matrix-logo {
    margin-right: 0.5em;
    height: 27px;
  }

  .matrix-title {
    font-family: $font-family-display;
    font-weight: 500;
    font-size: $font-size-interface-extra-large;
    color: $off-white;
  }

  &-section {
    display: flex;
    align-items: center;
    height: 100%;

    .MainMenu-link,
    .TextButton {
      color: $off-white;
    }
  }

  &-link {
    height: 100%;
    display: flex;
    align-items: center;

    a,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0 0.625rem !important;

      &:hover {
        background-color: $dark-blue-hover;
      }
    }
  }

  &-user {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  &-user-image {
    max-height: 1.5rem;
    margin-right: 0.5em;

    img {
      max-height: 1.5rem;
      border-radius: 0.75rem;
    }
  }

  .TextButton {
    margin: 0;
  }

  button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    padding: 0;
  }
}

.HowToModal {
  font-family: $font-family-interface;
  color: $white;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: calc(440px + 0.5rem);

  &-title {
    font-weight: $font-weight-bold;
    font-size: $font-size-interface-medium-large;
  }

  &-description {
    font-weight: $font-weight-normal;
    font-size: $font-size-interface-medium;
  }

  &-buttons-container {
    display: flex;
    gap: 0.75rem;
  }

  &-button {
    border: 1px solid $dark-gray;
    background-color: $dark-blue;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    width: 220px;
    height: 220px;
    cursor: pointer;

    &:hover {
      background-color: $dark-blue-hover;
    }

    &-title {
      font-weight: $font-weight-bold;
      font-size: $font-size-interface-medium-large;
      text-align: center;
    }
    &-description {
      font-weight: $font-weight-normal;
      font-size: $font-size-interface-medium;
      text-align: center;
    }
  }
}
