@import './variables.scss';

%text {
  color: $text-color;
}

%text-display {
  @extend %text;
  font-family: $font-family-display;
}

.text-display-large {
  @extend %text-display;
  font-size: $font-size-display-large;
}

.text-display-medium {
  @extend %text-display;
  font-size: $font-size-display-medium;
}

.text-display-medium-500 {
  @extend %text-display;
  font-size: $font-size-display-medium;
  font-weight: 500;
}

.text-display-small {
  @extend %text-display;
  font-size: $font-size-display-small;
}

%text-interface {
  @extend %text;
  font-family: $font-family-interface;
}

%text-interface-medium-weight {
  @extend %text;
  font-family: $font-family-interface;
}

.text-interface-medium-500 {
  @extend %text-interface;
  font-size: $font-size-interface-medium;
  font-weight: 500;
}

.text-interface-large {
  @extend %text-interface;
  font-size: $font-size-interface-large;
  font-weight: bold;
}

.text-interface-medium {
  @extend %text-interface-medium-weight;
  font-size: $font-size-interface-medium;
  font-weight: normal;
}

.text-interface-small {
  @extend %text-interface-medium-weight;
  font-size: $font-size-interface-small;
  font-weight: normal;
}

.text-interface-plain {
  @extend %text-interface;
  font-size: $font-size-interface-small;
  font-weight: normal;
}

.text-interface-subtext {
  @extend %text-interface;
  color: $text-color-light;
  font-size: $font-size-interface-subtext;
  font-weight: normal;
  line-height: 1.5em;
}
