@import 'styles/variables';

.NodePill {
  height: 24px;

  font-family: $font-family-interface;
  font-size: 0.75rem;
  font-weight: 500;
  color: $dark-blue;
  cursor: pointer;

  &-icon {
    filter: $make-svg-dark-blue;
    &.disabled {
      filter: $make-svg-dark-gray;
    }
  }

  &-name {
    text-overflow: ellipsis;
    fill: $dark-blue;
    &.disabled {
      fill: $dark-gray;
    }
  }

  &-background {
    height: 100%;

    fill: $light-gray;

    // &.anthropogenic-factors {
    //   fill: $anthropogenic-factors;
    // }
    // &.secondary-consumers {
    //   fill: $secondary-consumers;
    // }
    // &.primary-consumers {
    //   fill: $primary-consumers;
    // }
    // &.primary-producers {
    //   fill: $primary-producers;
    // }
    // &.abiotic-factors {
    //   fill: $abiotic-factors;
    // }
    // &.decomposers {
    //   fill: $decomposers;
    // }
    &.disabled {
      fill: $off-white;
    }

    &.hover {
      fill: $light-gray-hover;

      // .anthropogenic-factors {
      //   fill: $anthropogenic-factors-hover;
      // }
      // .secondary-consumers {
      //   fill: $secondary-consumers-hover;
      // }
      // .primary-consumers {
      //   fill: $primary-consumers-hover;
      // }
      // .primary-producers {
      //   fill: $primary-producers-hover;
      // }
      // .abiotic-factors {
      //   fill: $abiotic-factors-hover;
      // }
      // .decomposers {
      //   fill: $decomposers-hover;
      // }
    }
  }
}
