@import 'styles/variables';

.ConnectionInput {
  &-alternative-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &-alternative-line {
    flex-grow: 1;
    height: 1px;
    background-color: $light-gray;
  }

  &-alternative-description {
    margin: 1rem;
    font-family: $font-family-interface;
    font-weight: $font-weight-normal;
    font-size: $font-size-interface-medium;
    color: $dark-gray;
    font-style: italic;
  }

  .add-connection-button {
    &.Button {
      font-weight: 500;
      font-size: 1rem;
      .Icon {
        filter: $make-svg-off-white;
      }
    }
  }
}
