@import 'styles/variables';

.App {
  display: flex;
  color: $text-color;
  flex-direction: row;
  font-family: $font-family-display;
  font-weight: bold;
  background-color: $white;
  min-height: 100vh;

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
