@import 'styles/variables';
@import 'components/menus/Menu.scss';

.Menu-tabs {
  color: $dark-gray;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 0.85em;

  box-shadow: 0 -0.5px 0 $off-white inset, 0 -1.5px 0 lightgray inset;

  width: 100%;

  .Tab {
    cursor: pointer;
    color: $dark-gray;
    font-family: $font-family-interface;
    font-size: 0.875em;
    font-style: normal;
    font-weight: $font-weight-bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    border: none;
    background: none;
    padding: 0 0 4px 0;
    margin: 0;

    &.selected {
      .Tab-icon {
        filter: $make-svg-dark-blue;
      }
      color: $dark-blue;
      border-bottom: 2px solid $green;
    }

    &-icon {
      filter: $make-svg-light-gray;
      stroke-width: 1px;
      max-height: 1rem;
      max-width: 1rem;
    }

    &.hasLabel.hasIcon {
      justify-content: space-between;
    }

    // When both label and icon are present, ensure some spacing between the two
    &-label + &-icon {
      margin-left: 0.5rem;
    }
  }
}
