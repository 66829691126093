.ImportConnections {
  align-items: center;
  display: flex;
  flex-direction: row;

  label {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  input {
    visibility: hidden;
    height: 0;
    width: 0;
  }
}
