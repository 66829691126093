@import 'styles/variables';

.ModalFooter {
  width: 100%;
  height: $modal-base-height;
  background-color: $off-white;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  align-items: center;

  &-cancel-button {
    background-color: unset !important;
  }

  &-button-container {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }

  &-footer-button {
    margin-right: 1rem;
  }

  &-close-button {
    background: none;
    color: $dark-blue;
  }
}
