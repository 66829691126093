@import 'styles/variables';

.InputField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0.75em;

  &-input {
    color: $dark-blue;
    font-weight: 400;
    font-size: 1.125em;
    width: 100%;
  }
  &-label {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
  }

  &-validationMessage.text-interface-plain {
    color: $red;
    padding: 0.25rem;
  }

  &-dark {
    .text-interface-subtext {
      color: white;
    }
  }
}
