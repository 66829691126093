@import '../styles/variables';

.SmallScreenWarning {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);

  &.hidden {
    display: none;
  }

  @media (min-width: 767px) and (min-height: 400px) {
    display: none;
  }
}

.dialog {
  background-color: $gray-100;
  font-family: $font-family-interface;
  width: 250px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  margin-bottom: 1em;
}
