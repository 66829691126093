@import 'styles/variables';

.BarChart {
  .gray {
    fill: $dark-gray;
  }

  .positive {
    fill: $blue;
  }

  .negative {
    fill: $red;
  }
}
