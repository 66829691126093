@import 'styles/variables';

.SectionHeader {
  color: $dark-blue;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 1.25rem;

  &-section-header {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }

  &-icon {
    filter: $make-svg-dark-blue;
  }

  &-label {
    margin-left: 1rem;
  }
}
