@import 'styles/variables';

.Popover {
  position: fixed;
  min-width: 300px;
  max-width: 500px;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.3);
  color: $white;
  z-index: 100000;
  top: 0;
  left: 0;
  background: $dark-blue;
  border-radius: $button-border-radius;
  font-family: $font-family-interface, sans-serif;
  opacity: 0;

  &.show {
    opacity: 1 !important;
  }

  &-title {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }

  &-section {
    width: 100%;
    margin-bottom: 0.75rem;
  }

  &-secondary-button {
  }

  &-destructive-button {
    &.pill-button {
      background-color: $red;
      border-color: $red;
      color: $white;
    }
  }

  &-link {
    background-color: $link-background;
    padding: 0.25rem;
    border: 1px solid $dark-gray;
    border-radius: 4px;
    width: 100%;
    // TODO come back and finesse, this matches dropdown height from library
    min-height: 38px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;

    &:hover {
      color: $white;
      .Popover-link-icon {
        filter: $make-svg-off-white;
      }
    }

    &-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      filter: $make-svg-light-gray;
    }
  }

  &-content {
    color: $light-gray;
    font-size: 0.875rem;
    font-weight: normal;
  }
}
