@import 'styles/variables';

.MatrixSidebarConnections {
  &-trophic-level-name {
    font-size: $font-size-interface-small;
    font-weight: $font-weight-extra-bold;
    color: $dark-blue;
  }
}

svg.connections-graphic {
  stroke-width: 2;
  width: 100%;

  & :not(svg) *,
  & > :not(svg) {
    pointer-events: none;
  }

  .weight-label {
    text-anchor: middle;
    dominant-baseline: middle;
    fill: white;
    font-family: $font-family-interface, sans-serif;
    font-weight: bold;
    font-size: 10px;
  }

  rect {
    &.blue {
      fill: $blue;
    }

    &.red {
      fill: $red;
    }
  }

  line,
  path {
    fill: none;
    stroke-linecap: round;

    &.blue {
      stroke: $blue;
    }

    &.red {
      stroke: $red;
    }
  }
}
