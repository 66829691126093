@import './fonts.scss';
@import './variables.scss';

body {
  margin: 0;
  font-family: $font-family-display;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.modalEnabled {
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: inherit;
}

// -------------------------------------------
// BUTTONS

// Primary button large
.primary-button-large {
  display: inline-flex;
  padding: $button-padding-large;
  align-items: center;
  justify-content: center;
  border-radius: $button-border-radius;
  background-color: $green;
  cursor: pointer;
  white-space: nowrap;

  color: $white;
  font-family: 'Public Sans';
  font-size: $font-size-interface-large;
  font-style: normal;
  font-weight: $font-weight-semi-bold;

  .Icon {
    filter: $make-svg-off-white;
    width: 18px;
    height: 18px;
  }
}

.primary-button-large:hover {
  background-color: $green-hover;
}

.primary-button-large.disabled {
  background-color: $off-white;
  color: $dark-gray;
  cursor: not-allowed;

  .Icon {
    filter: $make-svg-dark-gray;
    width: 18px;
    height: 18px;
  }
}

// Primary button medium
.primary-button-medium {
  display: inline-flex;
  padding: $button-padding-medium;
  align-items: center;
  justify-content: center;
  border-radius: $button-border-radius;
  background-color: $green;
  cursor: pointer;
  white-space: nowrap;

  color: $white;
  font-family: 'Public Sans';
  font-size: $font-size-interface-medium;
  font-style: normal;
  font-weight: $font-weight-medium;

  .Icon {
    filter: $make-svg-off-white;
    width: 18px;
    height: 18px;
  }
}

.primary-button-medium:hover {
  background-color: $green-hover;
}

.primary-button-medium.disabled {
  background-color: $off-white;
  color: $dark-gray;
  cursor: not-allowed;

  .Icon {
    filter: $make-svg-dark-gray;
    width: 18px;
    height: 18px;
  }
}

// Primary button small
.primary-button-small {
  display: inline-flex;
  padding: $button-padding-small;
  align-items: center;
  justify-content: center;
  border-radius: $button-border-radius;
  background-color: $green;
  cursor: pointer;
  white-space: nowrap;

  color: $white;
  font-family: 'Public Sans';
  font-size: $font-size-interface-medium;
  font-style: normal;
  font-weight: $font-weight-medium;

  .Icon {
    filter: $make-svg-off-white;
    width: 18px;
    height: 18px;
  }
}

.primary-button-small:hover {
  background-color: $green-hover;
}

.primary-button-small.disabled {
  background-color: $off-white;
  color: $dark-gray;
  cursor: not-allowed;

  .Icon {
    filter: $make-svg-dark-gray;
    width: 18px;
    height: 18px;
  }
}

// Secondary button large
.secondary-button-large {
  display: inline-flex;
  padding: $button-padding-large;
  align-items: center;
  justify-content: center;
  border-radius: $button-border-radius;
  cursor: pointer;
  white-space: nowrap;

  background-color: $light-gray;
  color: $dark-blue;
  font-family: 'Public Sans';
  font-size: $font-size-interface-large;
  font-style: normal;
  font-weight: $font-weight-semi-bold;

  .Icon {
    filter: $make-svg-dark-blue;
    width: 18px;
    height: 18px;
  }
}

.secondary-button-large:hover {
  background-color: $light-gray-hover;
}

.secondary-button-large.disabled {
  background-color: $off-white;
  color: $dark-gray;
  cursor: not-allowed;

  .Icon {
    filter: $make-svg-dark-gray;
    width: 18px;
    height: 18px;
  }
}

// Secondary button small
.secondary-button-medium {
  display: inline-flex;
  padding: $button-padding-medium;
  align-items: center;
  justify-content: center;
  border-radius: $button-border-radius;
  cursor: pointer;
  white-space: nowrap;
  max-height: 36px;

  background-color: $light-gray;
  color: $dark-blue;
  font-family: 'Public Sans';
  font-size: $font-size-interface-medium;
  font-style: normal;
  font-weight: $font-weight-medium;

  .Icon {
    filter: $make-svg-dark-blue;
    width: 18px;
    height: 18px;
  }
}

.secondary-button-medium:hover {
  background-color: $light-gray-hover;
}

.secondary-button-medium.disabled {
  background-color: $off-white;
  color: $dark-gray;
  cursor: not-allowed;

  .Icon {
    filter: $make-svg-dark-gray;
    width: 18px;
    height: 18px;
  }
}

// Secondary button small
.secondary-button-small {
  display: inline-flex;
  padding: $button-padding-small;
  align-items: center;
  justify-content: center;
  border-radius: $button-border-radius;
  cursor: pointer;
  white-space: nowrap;

  background-color: $light-gray;
  color: $dark-blue;
  font-family: 'Public Sans';
  font-size: $font-size-interface-medium;
  font-style: normal;
  font-weight: $font-weight-medium;

  .Icon {
    filter: $make-svg-dark-blue;
    width: 18px;
    height: 18px;
  }
}

.secondary-button-small:hover {
  background-color: $light-gray-hover;
}

.secondary-button-small.disabled {
  background-color: $off-white;
  color: $dark-gray;
  cursor: not-allowed;

  .Icon {
    filter: $make-svg-dark-gray;
    width: 18px;
    height: 18px;
  }
}

// Secondary button dark small
.secondary-button-dark-small {
  display: inline-flex;
  padding: $button-padding-small;
  align-items: center;
  justify-content: center;
  border-radius: $button-border-radius;
  background-color: $dark-blue;
  border: 1px solid $dark-gray;
  cursor: pointer;
  white-space: nowrap;

  color: $white;
  font-family: 'Public Sans';
  font-size: $font-size-interface-medium;
  font-style: normal;
  font-weight: $font-weight-medium;

  .Icon {
    filter: $make-svg-off-white;
    width: 18px;
    height: 18px;
  }
}

.secondary-button-dark-small:hover {
  background-color: $dark-blue-hover;
}

.secondary-button-dark-small.disabled {
  background-color: $light-gray;
  color: $dark-gray;
  cursor: not-allowed;
}

// Secondary button dark medium
.secondary-button-dark-medium {
  display: inline-flex;
  padding: $button-padding-medium;
  align-items: center;
  justify-content: center;
  border-radius: $button-border-radius;
  background-color: $dark-blue;
  border: 1px solid $dark-gray;
  cursor: pointer;
  white-space: nowrap;

  color: $white;
  font-family: 'Public Sans';
  font-size: $font-size-interface-medium;
  font-style: normal;
  font-weight: $font-weight-medium;

  .Icon {
    filter: $make-svg-off-white;
    width: 18px;
    height: 18px;
  }
}

.secondary-button-dark-medium:hover {
  background-color: $dark-blue-hover;
}

.secondary-button-dark-medium.disabled {
  background-color: $light-gray;
  color: $dark-gray;
  cursor: not-allowed;
}

// Tertiary button
.tertiary-button {
  display: inline-flex;
  padding: $button-padding-medium;
  align-items: center;
  justify-content: center;
  border-radius: $button-border-radius;
  background-color: $white;
  cursor: pointer;
  white-space: nowrap;

  color: $dark-blue;
  font-family: 'Public Sans';
  font-size: $font-size-interface-medium;
  font-style: normal;
  font-weight: $font-weight-medium;

  .Icon {
    filter: $make-svg-dark-blue;
    width: $font-size-interface-small;
    height: $font-size-interface-small;
    margin-left: unset;
    margin-right: 0.25rem;
    width: 18px;
    height: 18px;
  }
}

.tertiary-button:hover {
  background-color: $white-hover;
}

// Text button
.text-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;

  color: $dark-blue;
  font-family: 'Public Sans';
  font-size: $font-size-interface-small;
  font-style: normal;
  font-weight: $font-weight-medium;

  .Icon {
    filter: $make-svg-dark-blue;
    width: $font-size-interface-small;
    height: $font-size-interface-small;
    margin-left: unset;
    margin-right: 0.25rem;
    width: 18px;
    height: 18px;
  }
}

// Destructive button small
.destructive-button-small {
  display: inline-flex;
  padding: $button-padding-small;
  align-items: center;
  justify-content: center;
  border-radius: $button-border-radius;
  background-color: $red;
  cursor: pointer;
  white-space: nowrap;

  color: $white;
  font-family: 'Public Sans';
  font-size: $font-size-interface-medium;
  font-style: normal;
  font-weight: $font-weight-medium;

  .Icon {
    filter: $make-svg-off-white;
    width: 18px;
    height: 18px;
  }
}

.destructive-button-small:hover {
  background-color: $red-hover;
}

.destructive-button-small.disabled {
  background-color: $light-gray;
  color: $dark-gray;
  cursor: not-allowed;
}

// Pill button selected
.pill-button-selected {
  display: inline-flex;
  padding: $button-padding-small;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  background-color: $dark-blue;
  cursor: pointer;
  white-space: nowrap;

  color: $off-white;
  font-family: 'Public Sans';
  font-size: $font-size-interface-medium;
  font-style: normal;
  font-weight: $font-weight-semi-bold;
}

.pill-button-selected:hover {
  background-color: $dark-blue-hover;
}

.pill-button-selected.disabled {
  background-color: $light-gray;
  color: $dark-gray;
  cursor: not-allowed;
}

.pill-button-selected.border {
  border: 1px solid $dark-gray;
}

// Pill button unselected
.pill-button-unselected {
  display: inline-flex;
  padding: $button-padding-small;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  background-color: $light-gray;
  cursor: pointer;
  white-space: nowrap;

  color: $dark-blue;
  font-family: 'Public Sans';
  font-size: $font-size-interface-medium;
  font-style: normal;
  font-weight: $font-weight-semi-bold;
}

.pill-button-unselected:hover {
  background-color: $light-gray-hover;
}

.pill-button-unselected.disabled {
  background-color: $light-gray;
  color: $dark-gray;
  cursor: not-allowed;
}

// Icon button
.icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: transparent;
  width: 18px;
  height: 18px;
  cursor: pointer;

  .Icon {
    filter: $make-svg-dark-blue;
    width: 18px;
    height: 18px;
    margin-left: unset;
  }
}

.icon-button:hover {
  background-color: $light-gray-hover;
}

.icon-button.disabled {
  filter: $make-svg-light-gray;
  cursor: not-allowed;
}

.icon-button.selected {
  background-color: $light-gray-hover;
}

// Icon button small
.icon-button-small {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: transparent;
  width: 14px;
  height: 14px;
  cursor: pointer;

  .Icon {
    filter: $make-svg-dark-gray;
    width: 14px;
    height: 14px;
    margin-left: unset;
  }
}

.icon-button-small:hover {
  background-color: $light-gray-hover;
}

.icon-button-small.disabled {
  filter: $make-svg-light-gray;
  cursor: not-allowed;
}

.icon-button-small.selected {
  background-color: $light-gray;
}

// Icon button extra small
.icon-button-extra-small {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: transparent;
  width: 12px;
  height: 12px;
  cursor: pointer;

  .Icon {
    filter: $make-svg-dark-blue;
    width: 12px;
    height: 12px;
    margin-left: unset;
  }
}

.icon-button-extra-small:hover {
  background-color: $light-gray-hover;
}

.icon-button-extra-small.disabled {
  filter: $make-svg-light-gray;
  cursor: not-allowed;
}

.icon-button-extra-small.selected {
  background-color: $light-gray;
}

// Button icon classes

.icon-gray {
  filter: $make-svg-dark-gray;
  width: 18px;
  height: 18px;

  &.disabled {
    filter: $make-svg-dark-gray;
  }
}

.icon-dark-blue {
  filter: $make-svg-dark-blue;
  width: 18px;
  height: 18px;

  &.disabled {
    filter: $make-svg-dark-gray;
  }
}

.icon-white {
  filter: $make-svg-off-white;
  width: 18px;
  height: 18px;

  &.disabled {
    filter: $make-svg-dark-gray;
  }
}

.icon-green {
  filter: $make-svg-green;
  width: 18px;
  height: 18px;

  &.disabled {
    filter: $make-svg-dark-gray;
  }
}

// Button image classes

.button-image-sized {
  max-height: 1.75rem;
  max-width: 1.75rem;
}
