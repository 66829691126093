.MPGLink {
  font-size: 0.875rem;

  &-logo-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}
