@import 'styles/variables';

.PlayModeWhatChanged {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  height: 100%;
  padding: 1rem 1.5rem 1rem 1.5rem;
  background-color: $white;

  &-content {
    overflow: auto;
    // Accommodates the outline element on inputs
    padding: 2px;
  }

  &-scroll-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    height: fit-content;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font-family: $font-family-interface;

    &-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      font-weight: $font-weight-extra-bold;
      font-size: $font-size-interface-extra-large;
      color: $dark-blue;
    }

    &-description {
      font-weight: $font-weight-normal;
      font-size: $font-size-interface-small;
      color: $dark-gray;
      font-style: italic;
    }
  }
}

.NodeBar {
  display: flex;
  width: 100%;
  gap: 0.5rem;

  &.fade-out {
    opacity: 0.5;
  }

  &-image {
    height: 3rem;
    width: 3rem;
    min-width: 3rem;
    border-radius: 50%;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &-data {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 3rem;
    gap: 0.5rem;
    justify-content: center;

    &-name {
      font-family: $font-family-interface;
      font-size: $font-size-interface-small;
      font-weight: $font-weight-semi-bold;
    }

    &-graph {
      display: flex;
      width: 100%;
      gap: 0.5rem;
    }

    &-bar {
      height: 1rem;
      background-color: $dark-gray;
      border-radius: 2px;

      &.positive {
        background-color: $blue;
      }
      &.negative {
        background-color: $red;
      }
      &.fixed {
        background-color: $green;
      }
    }

    &-numbers {
      font-family: $font-family-interface;
      font-size: $font-size-interface-small;
      font-weight: $font-weight-medium;

      &.positive {
        color: $blue;
      }
      &.negative {
        color: $red;
      }
      &.fixed {
        color: $green;
      }
    }
  }
}
