@import 'styles/variables';

.ConnectionsModal {
  &-menu {
    margin-top: 0.5em;
  }

  &-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
  }
}
