.MatrixChartContainer {
  height: 100%;
  flex-grow: 1;
}

.save-experiment-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 2;
  padding: 10px 15px;
  border-radius: 8px;
}
