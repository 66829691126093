@import 'styles/variables';

.LoginPopoverContent {
  &-actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    height: 4.6rem;
    margin-right: 0.2em;
  }
  .Modal-content {
    flex-direction: column;
    justify-content: space-between;
    max-width: 55vw;
    min-height: 40vh;
  }

  .disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  //targets the email sign in box
  .mdl-card {
    margin-bottom: 1.25em;
  }

  fieldset {
    padding: 0;
    border: none;
  }

  &-title {
    color: $dark-blue;
    font-family: $font-family-display;
    font-weight: 400;
    font-size: 2em;
    margin-bottom: 1rem;
  }
  &-body {
    display: flex;
    flex-direction: column;
    gap: 1em;
    overflow-y: auto;
  }
  &-title {
    color: $dark-blue;
    font-family: $font-family-display;
    font-weight: 400;
    font-size: 2em;
  }
  &-body {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  &-close {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .Checkbox {
    margin-right: 0.75em;

    &-label-container {
      display: flex;
      align-items: center;
    }

    &-label {
      font-family: $font-family-interface;
      font-weight: 400;
      white-space: nowrap;
    }
  }

  .text-interface-plain {
    color: $light-gray;
  }

  .firebaseui-page-provider-sign-in {
    margin: 0;
    max-width: none;

    .firebaseui-card-content {
      padding: 0;

      .firebaseui_container {
        padding: 0;
      }

      .firebaseui-idp-list {
        display: flex;
        width: 100%;
        gap: 0.5rem;
        padding: 0;
        margin: unset;
      }

      .firebaseui-list-item {
        flex-grow: 1;
        padding: 0;
        margin-bottom: unset;
      }

      .firebaseui-idp-button {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: none;
        border: 1px solid $light-gray;
        box-shadow: 0px 0px 3px rgba(167, 167, 167, 0.25);
        border-radius: 6px;
        background-color: $white !important;
      }
      .firebaseui-idp-icon-wrapper {
        img {
          height: 26px;
          width: 26px;
        }
      }
      .firebaseui-idp-text {
        font-family: $font-family-interface;
        font-weight: 600;
        color: $dark-blue;
        font-size: 1rem;
      }
      form > ul > li:nth-child(2) > button {
        img {
          filter: $make-svg-dark-blue;
        }
      }
    }
  }
}
