@import 'styles/variables';

.StatsView {
  background-color: $white;
  width: 100%;

  &-menu {
    margin-right: 2rem;
  }

  &.add-right-margin {
    margin-right: 2em;
  }
}

.stats {
  font-family: $font-family-interface;
  color: $dark-blue;

  &-layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 1.5em;

    .histogram-flex {
      flex: 3 1 500px;
    }

    .non-histogram-flex {
      flex: 2 1 200px;
    }
  }

  &-title {
    font-size: 1.5em;
    font-weight: normal;
    margin-bottom: 0.5em;
  }

  &-section {
    background: $off-white;
    border-radius: 8px;
    padding: 1.5em 0.75em 1.5em 1.25em;

    &-title {
      color: $dark-gray;
      font-weight: 400;
      font-size: 1.25em;
      margin-bottom: 0.75em;
    }
  }

  &-value {
    font-size: 1.75em;
    font-weight: 600;
    color: $dark-blue;
    &.infinity {
      font-size: 3em;
      height: 1em;
    }
  }

  &-name {
    margin-top: 0.5em;
    font-weight: 700;
    font-size: 0.75em;
  }

  &-definition {
    margin-top: 0.5em;
    color: $dark-gray;
    font-weight: 400;
    font-size: 0.75em;
  }

  &-column {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
  }

  &-row {
    display: flex;
    flex-direction: row;
    padding: 0em;
    gap: 1.5em;
  }

  &-dividing-bar {
    height: 0;
    border: 1px solid $off-white;
    &-vertical {
      border: 1px solid $off-white;
    }
  }

  &-advanced-subgroup {
    margin-right: 1.5em;
  }
}

.histogram {
  margin-bottom: 0;
  padding-bottom: 0;

  &-wrapper {
    min-width: 300px;
  }

  &-container {
    display: inline-block;
    position: relative;
    width: 100%;
    vertical-align: top;
    overflow: hidden;
  }

  &-bar {
    fill: $dark-blue;
    vertical-align: bottom;
    padding: 0.25em;

    &.negative {
      fill: $red;
    }

    &.positive {
      fill: $blue;
    }

    &-label {
      font-size: 0.75em;
      text-align: center;
      fill: $white;
      &-short {
        fill: $dark-blue;
        &.positive-label {
          fill: $blue;
        }
        &.negative-label {
          fill: $red;
        }
      }
    }
  }

  &-bar-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5em;
    color: $off-white;
  }

  &-axis-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5em;
    color: $dark-blue;
  }

  &-axis {
    color: $white;
    stroke-width: 2px;
    text {
      font-family: $font-family-interface;
      font-size: 1em;
      font-weight: 400;
      color: $dark-gray;
    }

    &-label {
      margin-top: 0;
      margin-bottom: 0.75em;
      padding-top: 0;
      text-align: center;

      font-family: $font-family-interface;
      font-size: 0.7em;
      font-weight: 400;
      color: $dark-gray;
    }
  }
}
