.InputColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  gap: 6px;
  &.double-gap {
    gap: 12px;
  }
}
