@import 'styles/variables';

.Checkbox {
  &-label-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  background: $dark-blue; /* this is needed for iOS. Otherwise the background just becomes black. */
  border: 1px solid $dark-gray;
  min-width: 16px;
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 3px;
  vertical-align: text-bottom;
  position: relative;

  &[disabled],
  &[aria-disabled='true'] {
    opacity: 0.4;
  }

  &::before {
    content: '';
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    top: 0;
  }
}

input[type='checkbox']:checked {
  background-color: $dark-blue;
}

input[type='checkbox']:checked::before {
  border-left: 2px solid $white;
  border-bottom: 2px solid $white;
  height: 44%;
  width: 66%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  transform: rotate(-55deg) translateY(-12%) translateX(12%);
}
