@import 'styles/variables';

.MatrixSidebar {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  font-family: $font-family-interface;
  font-weight: normal;
  display: flex;
  flex-direction: column;

  background-color: white;

  &-section-header-container {
    margin-bottom: 0.5rem;
  }

  &-header {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;

    &-bold {
      font-size: $font-size-interface-large;
      font-weight: $font-weight-extra-bold;
    }

    &-text {
      font-family: $font-family-interface;
      font-size: $font-size-interface-small;
    }
  }

  &-stickyTopContainer {
    position: sticky;
    top: 0;
    height: 0;
    z-index: 1;

    .SectionHeader-icon {
      padding: 1rem;
      cursor: pointer;
    }
  }

  &-scrollContainer {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding: 1rem;
  }

  &-sectionIcon {
    filter: $make-svg-dark-blue;
    position: absolute;
    left: 12px;
    top: 1.25rem;
  }

  &-Modifications {
    width: 300px;
    box-shadow: -0.125rem 0 0.25rem 0 $light-gray;
    right: 0;

    .SectionHeader-icon {
      cursor: pointer;
    }
  }

  &.disabled {
    width: 40px;
  }

  &-toggle {
    position: absolute;
    left: -10px;
    top: 45px;
    background: white;
    border: 0.5px solid #dddddd;
    box-shadow: 0 0 3px rgba(167, 167, 167, 0.25);
    border-radius: 2px;
    font-size: 24px;
    padding-bottom: 0.2em;
    cursor: pointer;
    z-index: 1;
  }

  .title-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  h3 {
    margin: 1rem 0 1rem 6px;
    font-weight: 500;
  }

  &-sectionDescription {
    margin: 8px 0;
  }

  &-descriptionToggle {
    background: none;
    border: none;
    cursor: pointer;
    color: $dark-gray;
    font-size: 14px;
    display: flex;
    align-items: center;

    img {
      display: block;
      margin-right: 4px;
    }
  }

  hr {
    height: 1px;
    background-color: #dddddd;
    border: none;
  }
}

.ChangesToggle {
  &-button-container {
    display: inline-flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
