//
// Colors
//

$dark-blue: #102026;
$green: #9fab5a;
$blue: #4fb7cf;
$red: #f46069;
$dark-gray: #707b7f;
$light-gray: #e2e9eb;
$white: #ffffff;
$off-white: #f6faf9;
$purple: #7b78b2;

// Low visibility
$green-10: #9fab5a10;

$modal-background: #30375280;
$link-background: #445358;

$anthropogenic-factors: #d6e4ee;
$secondary-consumers: #fae3de;
$primary-consumers: #e6deed;
$primary-producers: #deecdc;
$decomposers: #e3e2e0;
$abiotic-factors: #faf3cc;

// TODO need all hover colors
$dark-blue-hover: #213e49;
$green-hover: #899543;
$light-gray-hover: #cedade;
$red-hover: #cc434c;

$dark-gray-hover: #73767e;
$white-hover: #f8f8f8;

//
// TODO/Need to confirm colors
//

$anthropogenic-factors-hover: #a8d0ec;
$secondary-consumers-hover: #ffb7a8;
$primary-consumers-hover: #cfb0de;
$primary-producers-hover: #b8dbb3;
$decomposers-hover: #bfbebc;
$abiotic-factors-hover: #ffed86;

//svg color filters (https://codepen.io/sosuke/pen/Pjoqqp)
$make-svg-light-gray: brightness(0) saturate(100%) invert(88%) sepia(14%)
  saturate(159%) hue-rotate(163deg) brightness(110%) contrast(84%);
$make-svg-dark-blue: brightness(0) saturate(100%) invert(8%) sepia(51%)
  saturate(586%) hue-rotate(151deg) brightness(93%) contrast(92%);
$make-svg-off-white: brightness(0) saturate(100%) invert(100%) sepia(11%)
  saturate(354%) hue-rotate(142deg) brightness(100%) contrast(96%);
$make-svg-dark-gray: brightness(0) saturate(100%) invert(47%) sepia(8%)
  saturate(414%) hue-rotate(150deg) brightness(98%) contrast(91%);
$make-svg-green: brightness(0) saturate(100%) invert(72%) sepia(14%)
  saturate(1154%) hue-rotate(29deg) brightness(88%) contrast(87%);

$make-svg-dark-blue-hover: brightness(0) saturate(100%) invert(20%) sepia(6%)
  saturate(3520%) hue-rotate(151deg) brightness(101%) contrast(93%);

//
// TODO/Need to remove in favor of confirmed palette
//

$gray-100: #f7fafc;
$gray-200: #edf2f7;
$gray-300: #e2e8f0;
$gray-400: #cbd5e0;
$gray-500: #a0aec0;
$gray-600: #718096;

$text-color: #4a5568;
$text-color-light: #718096;

//
// Fonts
//
$font-family-display: 'Lora', serif;
$font-family-interface: 'Public Sans', sans-serif;

$font-size-display-large: 1.75rem;
$font-size-display-medium: 1.5rem;
$font-size-display-small: 1.25rem;

$font-size-interface-extra-large: 1.5rem;
$font-size-interface-large: 1.25rem;
$font-size-interface-medium-large: 1rem;
$font-size-interface-medium: 0.875rem;
$font-size-interface-small: 0.75rem;
$font-size-interface-subtext: 0.75rem;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

//
// Fixed heights
//
$top-bar-height: 40px;

//
// Screen sizes
//
$screen-width-medium: 1000px;

//
// Modal
//
$modal-base-height: 4.6rem;
$modal-border-radius: 12px;

// Buttons
$button-padding-small: 0.375rem 0.625rem;
$button-padding-medium: 0.625rem 1rem;
$button-padding-large: 0.75rem 1.25rem;
$button-border-radius: 4px;

// Checkbox
$checkbox-border-radius: 3px;
