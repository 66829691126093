@import 'styles/variables';

$vertical-grid-gap: 1.25rem;

.Menu {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  gap: $vertical-grid-gap;
  color: $dark-blue;

  &-caret-container {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }

  &-name {
    font-style: normal;
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    gap: 0.625rem;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;

    &-primary {
      font-family: $font-family-display;
      font-weight: $font-weight-bold;
      font-size: $font-size-display-medium;
      color: $dark-blue;
    }

    &-secondary {
      font-family: $font-family-display;
      font-weight: $font-weight-bold;
      font-size: $font-size-display-medium;
      color: $dark-gray;
    }

    .Button {
      margin-left: 1rem;
    }
  }

  &-text {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-items: flex-start;
    gap: $vertical-grid-gap;
  }

  &-actions {
    box-sizing: border-box;
    display: flex;
    font-size: 1.5em;
    font-weight: bold;
    .text-interface-plain {
      font-weight: bold;
    }
  }

  &-description {
    font-size: 0.875rem;
    color: $dark-gray;
  }

  &-section {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: $font-family-interface;
    font-weight: normal;
    font-size: 14px;
    gap: 12px;

    .back-arrow {
      max-height: 1.25rem;
      max-width: 1.25rem;
      height: 1.25rem;
      padding: 0;
      margin: 0;
    }
  }

  @media (max-width: $screen-width-medium) {
    align-items: start;
    flex-direction: column;

    &-text {
      max-width: unset;
    }
    &-actions {
      align-self: flex-end;
    }
  }
}
