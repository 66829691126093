@import 'styles/variables';

.MatrixSettingsSection {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 50vw;
  min-height: 60vh;
  overflow-y: auto;

  &-sections {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &-label {
    margin-bottom: 0.5rem;
  }

  &-input-container {
    min-height: 2.5rem;
    display: flex;
    align-items: center;
  }

  &-title {
    font-family: $font-family-interface;
    font-size: $font-size-interface-extra-large;
    font-weight: 500;

    &-container {
      display: flex;
      align-items: baseline;
      gap: 1rem;
      height: 4.5rem;
    }

    &-icon {
      width: 1.25rem;
      height: 1.25rem;
      cursor: pointer;
      filter: $make-svg-dark-gray;

      &.disabled {
        filter: $make-svg-off-white !important;
        pointer-events: none;
      }

      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-created-by {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-family: $font-family-interface;
    font-size: $font-size-interface-medium;
    color: $dark-gray;
    margin-bottom: 1rem;

    &-edit {
      display: inline-block;
    }
  }

  .page-description {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  &-text {
    margin-bottom: 2rem;
    width: 50%;
  }
  &-main {
    height: 100%;
    overflow-y: auto;
    padding: 2.5em 2.5em 0px;
  }

  .InputField {
    margin-bottom: 0;
  }

  &-actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    height: $modal-base-height;
    margin-right: 0.2em;
    .TextButton {
      font-size: 1rem;
      padding: 1rem;
    }
    .Button {
      font-size: 1rem;
    }

    span {
      margin: 0 0.5rem;
    }

    &-save-discard {
      align-items: center;
      display: flex;
    }
  }

  .InputField {
    margin: 0;
  }
  .left-fill {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
  }

  p {
    color: $dark-gray;
    font-family: $font-family-interface;
    font-weight: 400;
    font-size: 1em;
  }

  .setting-description-text {
    color: $dark-gray;
    font-family: $font-family-interface;
    font-weight: 400;
    font-size: 0.75em;

    &-large {
      color: $dark-gray;
      font-family: $font-family-interface;
      font-weight: 400;
      font-size: 1em;
      margin-bottom: 0.5rem;
    }
  }
}

.MatrixSettings {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &-row {
    display: flex;
    gap: 1rem;
  }

  &-half {
    width: 50%;
  }

  &-grow {
    flex-grow: 1;
  }

  &-shrink {
    min-width: 30%;
    flex-shrink: 1;
  }
}
