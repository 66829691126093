@import 'styles/variables';

.MatrixList {
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  margin-left: 2em;
  margin-bottom: 2rem;
  padding: 0.75em 1em 0 0;

  &-new-folder-button-container {
    display: inline-flex;
    margin-bottom: 0.5rem;
  }

  &-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2rem 2rem 0.5rem 2rem;

    &.text-interface-medium {
      color: #000000;
      font-family: $font-family-interface;
      font-size: 1.5em;
    }

    .Button {
      background: $white;
      padding-bottom: 0.1rem;
      padding-top: 0.1rem;
    }
  }

  .MatrixListDivider {
    height: 4px;
    background-color: #525c6f;
    width: 100%;
    margin-bottom: 1rem;
  }

  .MatrixSubList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    &-experiments {
      margin: 0;
      margin-left: 5em;
      padding: 0;

      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .MatrixListItem {
        &-matrixName {
          font-size: 0.95em;
        }
      }
    }
  }
}
