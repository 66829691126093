@import 'styles/variables';

.Tooltip {
  cursor: pointer;
  color: $dark-blue;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  margin-left: 0.5rem;
  position: relative;
  background-color: $light-gray;
  border-radius: 50%;
  width: 18px;
  height: 18px;

  &.active {
    background: $light-gray-hover;
  }

  &-text {
    color: $white;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 12px;
    font-family: $font-family-interface;
    font-weight: $font-weight-normal;
    font-size: $font-size-interface-small;
    background-color: $dark-blue;
    box-shadow: 0px 0px 3px rgba(167, 167, 167, 0.25);
    border-radius: 4px;
    top: 0px;
    left: 1.5rem;
    line-height: 1.25em;
    min-width: 18em;
    max-width: 240px;
    position: absolute;
    z-index: 5000;
    text-wrap: wrap;

    &.left {
      transform: translateX(calc(-240px - 0.5rem));
    }
  }
}
