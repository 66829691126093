@import 'styles/variables';

.GalleryFavoriteList {
  display: flex;
  flex-direction: column;
  width: calc(100% - 4em);
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 2em;
  margin-right: 2em;

  &-favorites-row {
    display: flex;
    width: 100%;
    overflow-x: auto;
  }

  &-favorite-header {
    width: 100%;
    display: flex;
    margin-bottom: 12px;
    color: $dark-blue;
    font-family: $font-family-interface;
    font-size: $font-size-interface-large;
    font-weight: $font-weight-normal;
  }

  &-favorite-icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }

  &-empty-state {
    min-height: 80px;
    color: $dark-gray;
    font-family: $font-family-interface;
    font-weight: 400;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.GalleryFavoriteItem {
  cursor: pointer;
  display: flex;
  width: 260px;
  min-width: 260px;
  height: 164px;
  border: 2px solid $light-gray;
  border-radius: 12px;
  flex-direction: column;
  margin-right: 12px;

  &-favorite-name {
    font-family: $font-family-interface;
    font-weight: $font-weight-medium;
    font-size: $font-size-interface-medium;
    color: $dark-blue;
  }

  &-favorite-image {
    margin-left: 20px;
    margin-top: 20px;
    flex-shrink: 0;
    height: 4rem;
    margin-right: 0.5rem;
    width: 6rem;
  }

  &-favorite-image img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &-favorite-image-bg-color img {
    background-color: #e2e8f0;
  }

  &-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 20px;
    margin-right: 20px;
    height: 100%;
  }

  &-modified-date-text {
    color: $dark-gray;
  }

  &-bottom-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.GalleryFavoriteItem:last-of-type {
  margin-right: unset;
}
