@import 'styles/variables';

.FolderEditPopover {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-family: $font-family-interface;
  max-width: 300px;

  .InputField {
    margin-bottom: unset;
  }

  &-text {
    font-size: $font-size-interface-small;
  }

  &-buttons-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .primary-button-small {
      width: 50%;
    }

    .secondary-button-dark-small {
      width: 50%;
    }
  }
}
