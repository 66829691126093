@import 'styles/variables';
.NoAccessView {
  margin: 2rem;

  button {
    border: 2px solid $dark-blue;
    border-radius: 10px;
  }

  a {
    text-decoration: underline;
  }
}
