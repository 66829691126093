@import 'styles/variables';

.GalleryMenu {
  padding-top: 1rem;

  .education-button {
    max-height: 2.25rem;
  }

  &-private-public-toggle-container {
    width: calc(200px + 0.5rem);
  }

  &-header {
    font-weight: 500;
  }

  &-button {
    margin-left: unset;
  }

  &-new-matrix-button {
    border-color: $light-gray !important;
    color: $dark-gray !important;
    height: calc(2.5rem - 6px);

    .Button-icon {
      height: 18px;
      width: 18px;
    }
  }

  .Menu-actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-family: $font-family-interface;
    font-weight: 600;
    font-size: $font-size-interface-medium;

    .InputField {
      flex: 0 1 160px;
      margin: 0 0 0 0.5em;
    }
  }
  .MatrixVisibilityPicker {
    display: flex;
    align-items: center;
    gap: 0.75em;
    min-width: 410px;
    overflow-x: hidden;

    .inspired-classroom {
      min-width: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.25em;
      flex: 0 1 auto;

      .Button-label {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }

      padding: 0.25em 0.5em;

      font-size: 1em;
      font-weight: 500;
    }

    .Button {
      margin: 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .Menu {
    .Button {
      margin-left: unset;
    }
  }
}
