@import 'styles/variables';

.TextField {
  width: 100%;

  &-input {
    width: 100%;
    border: 1px solid $light-gray;
    border-radius: $button-border-radius;
    padding-left: 0.25rem;
  }

  &.dark {
    .InputField-input {
      background-color: $link-background;
      padding: 0.25rem;
      border-radius: $button-border-radius;
      width: 100%;
      // TODO come back and finesse, this matches dropdown height from library
      min-height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $light-gray;

      input {
        box-shadow: none;
        border: none;
        background-color: $link-background;
        color: $white;

        &::placeholder {
          color: $dark-gray;
        }

        &:focus {
          box-shadow: none;
          border: none;
          outline: none;
        }
      }
    }
  }

  &:focus {
    outline-color: $dark-gray;
  }
}
