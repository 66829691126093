@import 'styles/variables';

.FolderDeletePopoverContent {
  &-buttons-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .destructive-button-small {
      width: 50%;
    }

    .secondary-button-dark-small {
      width: 50%;
    }
  }
}
