@import 'styles/variables';

.TextButton {
  align-items: center;
  background: none;
  border: none;
  color: $dark-blue;
  cursor: pointer;
  display: flex;
  font-family: $font-family-interface;
  font-size: 1em;
  justify-content: center;

  &-label + &-icon {
    margin-left: 0.5rem;
  }

  &-icon {
    max-height: 0.75rem;
    max-width: 0.75rem;
  }

  &.destructive {
    color: $red;
  }
}
