@import "styles/variables";

.ConnectionSingleValue {
  align-items: center;
  display: flex;

  &-icon {
    margin: 0 0.5rem;
  }
  &-currentNode {
    color: $gray-500;
  }
}
