@import 'styles/variables';

.GalleryView {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  margin-bottom: 2rem;
  padding: 0;

  &-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2rem 2rem 0.5rem 2rem;

    &.text-interface-medium {
      color: #000000;
      font-family: $font-family-display;
      font-size: 1.5em;
    }
  }
}

.GalleryAndList {
  background-color: white;
  min-height: 100vh;
  overflow-y: auto;
  width: 100%;

  &-header {
    align-items: center;
    display: flex;
    margin: 1rem 2rem;
    img {
      margin-right: 0.5rem;
    }
    h1 {
      margin: 0;
    }
    &:hover {
      color: black;
      cursor: pointer;
    }
  }
  &-buttons {
    margin: 1rem 2rem;
    button {
      width: 100%;
    }
  }
  @media (max-width: $screen-width-medium) {
    min-width: 15rem;
  }
}

.GalleryView {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 2rem;
  padding: 0;

  &-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2rem 2rem 0.5rem 2rem;

    &.text-interface-medium {
      color: #000000;
      font-family: $font-family-interface;
      font-size: 1.5em;
    }

    .Button {
      background: $white;
      padding-bottom: 0.1rem;
      padding-top: 0.1rem;
    }
  }
}

.GalleryMatrix {
  margin-top: 1.5rem;

  &:nth-of-type(odd) {
    width: 40%;
    height: 30em;
  }

  &:nth-of-type(even) {
    width: 60%;
    height: 30em;
  }

  &-experiments {
    display: none;
    margin: 0;
    padding: 0;

    .MatrixListItem {
      padding-left: 3rem;

      &-matrixName {
        font-size: 0.9em;
      }
    }
  }

  &.expanded {
    .GalleryMatrix {
      &-experiments {
        display: block;
      }
    }
  }
}
