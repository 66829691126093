@import 'styles/variables';

.MatrixSidebar-Connections {
  background-color: $off-white;
  border-left: 1px solid $light-gray;

  .MatrixSidebar-scrollContainer {
    padding: 0;
  }

  &-header {
    margin: 1rem 1rem 1rem 1rem;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;

    &-label {
      color: $dark-blue;
      font-family: $font-family-interface;
      font-weight: $font-weight-extra-bold;
      font-size: $font-size-interface-small;
    }

    &-button {
    }
  }

  &All {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #8a929f;
    margin-bottom: 4px;
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
  }

  .connections-graphic {
    width: 320px;
  }
}
