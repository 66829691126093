@import 'styles/variables';

.UncertaintyResults {
  flex-grow: 1;
  height: 0;
  &-container {
    height: 100%;
  }

  &-label {
    font-family: $font-family-display;
    font-size: $font-size-display-medium;
    color: $dark-blue;
  }

  &-header-container {
    display: flex;
    align-items: baseline;
    gap: 1rem;
  }

  &-button-container {
    width: 100%;
    display: flex;
    gap: 1rem;
  }

  &-tooltip {
    &-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      padding: 10px 12px;
      background: $dark-blue;
      box-shadow: 0px 0px 3px rgba(167, 167, 167, 0.25);
      border-radius: 4px;
      left: 1.25em;
      line-height: 1.25em;
      min-width: 18em;
      position: absolute;
      z-index: 5000;
    }

    &-section {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      font-family: $font-family-interface;
      color: $white;
      font-size: $font-size-interface-small;
    }

    &-key {
      font-weight: $font-weight-normal !important;
      white-space: nowrap;
    }

    &-value {
      font-weight: $font-weight-bold;
    }
  }
}
