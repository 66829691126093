@import 'styles/variables';

.UncertaintyResultsBarChart {
  &-title {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    font-family: $font-family-interface;
    font-size: $font-size-interface-large;
    font-weight: $font-weight-extra-bold;
    color: $dark-blue;
  }

  &-description {
    font-family: $font-family-interface;
    font-size: $font-size-interface-medium;
    font-weight: $font-weight-normal;
    color: $dark-gray;
    margin-top: 0.5rem;
  }
}
