@import 'styles/variables';

.Feedback {
  display: flex;
  padding: 2rem;
  justify-content: center;
  height: 100%;

  &-content {
    display: flex;
    flex-direction: column;
    width: 70%;
    max-width: 900px;
    height: 100%;
  }

  &-header {
    font-weight: 500;
    margin-bottom: 1rem;
  }

  &-type-buttons {
    margin-bottom: 1rem;
  }

  &-description {
    color: $text-color-light !important;
    margin-bottom: 1rem;
  }

  &-text-input {
    display: flex;
    min-height: 50%;
    flex-direction: column;

    .InputField {
      flex-grow: 1;
    }
    .InputField-input {
      height: 100%;
      textarea {
        height: 100%;
      }
    }
  }

  &-submit-button-container {
    .Button {
      border-radius: 0.5rem !important;

      &:disabled {
        background: $light-gray;
        cursor: not-allowed;
        border: 1px solid transparent;
        color: $dark-gray;
      }

      &:disabled:hover {
        background: $light-gray;
        cursor: not-allowed;
        border: 1px solid transparent;
        color: $dark-gray;
      }
    }
  }

  .Menu-actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-family: $font-family-interface;
    font-weight: 600;
    font-size: $font-size-interface-medium;

    .InputField {
      flex: 0 1 160px;
      margin: 0 0 0 0.5em;
    }
  }

  .MatrixVisibilityPicker {
    display: flex;
    align-items: center;
    gap: 0.75em;
    min-width: 410px;
    overflow-x: hidden;

    .Button {
      margin: 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .Menu {
    .Button {
      margin-left: unset;
    }
  }
}
