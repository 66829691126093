@import 'styles/variables';

.DetailsPage {
  padding: 1rem;
  background-color: $white;
  height: 100%;
  display: flex;
  flex-direction: column;

  // This just has to be large enough to alway encompass the whole chart
  &.for-image-download {
    height: 3000px !important;
  }

  &-top-bar {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;

    &-left {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    &-right {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }

  &-content {
    flex-grow: 1;
  }

  &-header {
    font-family: $font-family-interface;
    font-size: $font-size-interface-medium;
    font-weight: $font-weight-extra-bold;
    color: $dark-blue;
  }

  &-buttonset-container {
  }
}
