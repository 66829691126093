@import 'styles/variables';

.MatrixListItem {
  display: grid;
  height: 3.25rem;

  &-main {
    display: flex;
    cursor: pointer;

    &.isExperiment {
      grid-column-end: 2;
    }

    &.low-vis {
      opacity: 0.5;
    }

    &.draggable {
      cursor: grab;
    }
  }

  &-background {
    background-color: $off-white;
    align-items: center;
    color: black;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    grid-column-start: 1;
    grid-column-end: 3;
    border-radius: 8px;
    gap: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &:hover,
    &.highlighted {
      background-color: $light-gray;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }

  &-meta {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &-createdBy {
    font-size: 0.8em;

    &.text-interface-plain {
      color: black;
    }
  }

  &-modifiedDate.text-interface-plain {
    color: $gray-500;
  }

  &-name-container {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 0.5rem;
  }

  &-name {
    align-items: baseline;
    display: flex;
  }

  &-matrixName {
    font-family: $font-family-display;
    font-weight: 500;
    font-size: 1.25em;
    padding-left: 0.75em;
  }

  &-experimentName {
    font-family: $font-family-interface;
    font-size: 0.9em;
    font-weight: normal;
    margin-left: 1em;
  }

  &-image {
    flex-shrink: 0;
    height: 3.25rem;
    width: 4.25rem;

    margin-right: 0.5rem;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
      background-color: #e2e8f0;
    }
  }

  &-favorite-icon {
    width: 16px;
    height: 16px;

    &.hidden {
      opacity: 0;
    }
  }

  &-matrixDetails {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }

  &-experiments {
    display: none;
    margin: 0;
    padding: 0;
  }

  &-arrow {
    color: $gray-300;
    cursor: pointer;
    margin-right: -0.5rem;
    transition: transform 0.25s;
  }

  &.expanded {
    .SidebarListMatrix {
      &-experiments {
        display: block;
      }

      &-arrow {
        transform: rotate(90deg) translate(0.25rem, 0.25rem);
      }
    }
  }

  &-saveAsMatrix {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-left: 1em;
    background-color: $gray-200;
    grid-column-start: 2;
    grid-column-end: 2;
    font-family: $font-family-interface;
    font-size: 0.9em;
    color: black;
    cursor: pointer;
    padding: 0 0.5em;

    &:hover {
      background-color: $gray-300;
    }
  }

  .icon-container {
    display: flex;
  }
}
