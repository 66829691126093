@import 'styles/variables';

.SplashPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $off-white;

  &-content {
    display: flex;
    width: 80%;
    height: 100%;
    align-items: center;
    gap: 2rem;
  }

  &-left-column {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 1rem;
  }

  &-right-column {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  &-header {
  }

  &-description {
    color: $dark-gray !important;
  }

  &-bullets {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-left: 0.5rem;
  }

  &-bullet {
    font-size: 0.9rem;
  }

  &-button-container {
    display: flex;
    gap: 1rem;
  }
}

.PictureCarousel {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;

  &-image-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-prev-image {
    width: 100%;
    height: auto;
  }

  &-current-image {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: auto;

    opacity: 0;

    &.fade {
      transition: opacity 1s;
      opacity: 1;
    }
  }

  &-content {
    position: absolute;
    bottom: 0;
    background-color: rgba(48, 55, 82, 0.8);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    height: 120px;
    justify-content: space-between;
  }

  &-text-container {
  }

  &-header {
    color: $white !important;
    margin-bottom: 0.5rem;
  }

  &-description {
    color: $white !important;
    line-height: 1.25rem;
  }

  &-progress-line {
    display: flex;
    align-items: center;
    gap: 1.25rem;
  }

  &-progress-node {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: $dark-gray;
    cursor: pointer;

    &.selected {
      background-color: $white;
    }
  }
}
