@import 'styles/variables';

.NodeDescriptionModal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 500px;

  &-image-container {
    display: flex;
    border-radius: $modal-border-radius;
    max-height: 300px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      border-radius: $modal-border-radius;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &-text-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 100%;
    max-width: min-content;
  }

  &-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &-name {
    text-align: left;
    width: 100%;
    font-size: $font-size-interface-large;
    font-family: $font-family-interface;
    font-weight: $font-weight-semi-bold;
  }

  &-description {
    text-align: left;
    width: 100%;
    font-size: $font-size-interface-medium;
    font-family: $font-family-interface;
    font-weight: $font-weight-normal;
    line-height: $font-size-interface-large;
  }

  &-trophic-level {
    background-color: $light-gray;
    margin: 0;
    display: flex;
    width: fit-content;
    padding: 6px;
    border-radius: 4px;
    font-size: $font-size-interface-small;
    font-family: $font-family-interface;
    font-weight: $font-weight-normal;

    &-icon {
      display: block;
      margin-right: 4px;
    }
  }
}
