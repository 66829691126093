@import 'styles/variables';

.ExperimentResults {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  margin-right: 2rem;

  &-text {
    max-width: 50%;

    .ExperimentResults-title {
      color: $white;
      margin-bottom: 0.5rem;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    width: 100%;
  }

  &-controls {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    margin-top: 1.5em;
    margin-left: 1em;
  }

  &-note {
    font-family: $font-family-interface;
    font-size: 0.9em;
    font-weight: normal;
    margin-top: 1em;
    max-width: 10em;
  }

  &-Legend {
    font-family: $font-family-interface;
    font-size: 0.9em;
    font-weight: normal;
    margin-top: 1.5em;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .legend-row {
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    .legend-marker {
      background: black;
      height: 2px;
      margin-right: 0.25em;
      width: 1.5em;

      &-increase {
        background: $blue;
      }
      &-decrease {
        background: $red;
      }
    }
  }
}
