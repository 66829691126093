@import 'styles/fonts';
@import 'styles/variables';

.AbundanceSlider {
  min-width: 15rem;
  width: 100%;
  margin-bottom: 0.5em;

  &-slider-container {
    // Reflects the margin so the chart lines up with the label
    margin-left: -15px;
    width: calc(100% + 15px);
  }

  .tick {
    line {
      stroke-width: 2.5px;
      stroke-linecap: round;
    }
  }

  .tick-text {
    @extend .text-interface-subtext;
    fill: $dark-blue;
    font-size: 0.75em;
    font-weight: 500;
    text-anchor: middle;
  }

  .current-population-line {
    fill: $dark-gray;

    &-endcap {
      &-outer {
        fill: $white;
      }

      &-middle {
        fill: $dark-gray;
      }

      &-inner {
        fill: $white;
      }
    }
  }

  .currentPopulation {
    &.draggable {
      cursor: grab;
    }

    text {
      @extend .text-interface-plain;
      fill: $text-color;
      font-size: 0.75em;
      font-weight: 500;
      text-anchor: middle;
    }
  }

  .InputField-label {
    margin-bottom: 0;
  }

  //Abundance bar gradient colors
  .stop-left {
    stop-color: $anthropogenic-factors-hover;
  }

  .stop-middle {
    stop-color: $primary-consumers-hover;
  }

  .stop-right {
    stop-color: $secondary-consumers-hover;
  }
}
