.UncertaintyInputs {
  width: 100%;
  display: flex;
  gap: 1rem;

  &-column {
    width: 50%;
    flex-grow: 1;
  }
}
