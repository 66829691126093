@import 'styles/variables';

.InfoPage {
  margin: 1em;
  padding: 1rem 2rem;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;

  .content {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    margin-bottom: 0.75rem;
    font-weight: 500;
  }

  .description {
    font-size: 1em;
    line-height: 1.5rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  section {
    margin-bottom: 1.5rem;
  }

  .subtitle {
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    font-weight: 500;
  }

  .article-list {
    font-weight: normal;
    list-style: none;
    padding-left: 0;
  }

  .article-info {
    display: flex;
    margin-top: 0.5rem;

    div {
      margin-right: 1em;
    }
  }

  .article-author {
    font-weight: bold;
  }

  .article-date {
    font-weight: bold;
  }

  .article {
    font-family: $font-family-interface;
    margin-bottom: 0.25rem;
    margin-left: 2em;
    text-indent: -2em;
    margin-top: 1rem;
    margin-bottom: 1rem;
    .book {
      font-style: italic;
    }
  }

  img {
    max-height: 34px;
    max-width: 63px;
    margin: 0 10px;
    cursor: pointer;
  }

  .section-picker {
    li {
      cursor: pointer;
    }
  }

  .guide-frame {
    margin-bottom: 3em;
  }
}
