@import '../../styles/variables';

.ButtonSet {
  display: flex;
  gap: 0.5rem;
  width: 100%;

  &-option {
    border-radius: $button-border-radius !important;
    padding: $button-padding-medium !important;
    flex-grow: 1 !important;
  }
}
