@import 'styles/variables';

.MiniModal {
  background-color: $modal-background;
  mix-blend-mode: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;

  &-outside {
    display: flex;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  &-content {
    background-color: $white;
    color: $dark-blue;
    border-radius: $modal-border-radius;
    display: flex;
    max-width: 70vw;
    overflow: hidden;
    padding: 2rem;
    position: absolute;

    &.dark {
      background-color: $dark-blue;
      color: $white;
    }
  }
}
