@import 'styles/fonts';

.UncertaintyAnalysis {
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 2.5em 2.5em 0px;
    flex-grow: 1;
    height: 0;
    overflow: auto;
  }

  &-header {
    font-family: $font-family-interface;
    font-weight: $font-weight-extra-bold;
    font-size: $font-size-interface-extra-large;
    color: $dark-blue;
  }

  &-description {
    font-family: $font-family-interface;
    font-weight: $font-weight-normal;
    font-size: $font-size-interface-medium;
    color: $dark-gray;
  }

  &-bottom-border {
    min-height: 1px;
    height: 1px;
    background-color: $light-gray;
    width: 100%;
  }

  .iterations-input {
    align-self: flex-start;
  }
}

.NodeButtons {
  display: flex;
  width: 100%;
  flex-direction: column;

  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-nodes {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  &-pill {
    margin: 0.25rem;
    cursor: pointer;
  }

  &-selected-text {
    color: $dark-gray !important;
  }

  &-buttons {
    display: flex;
    align-items: center;
  }

  &-button {
    font-weight: bold;
    margin-left: 1rem;
    cursor: pointer;
  }
}
