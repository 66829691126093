@import 'styles/variables';

.MatrixMenu {
  background-color: $white;
  height: $top-bar-height;

  &-name-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &-buttons-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &-editing-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $off-white;
    color: $dark-gray;
    height: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 1rem;

    font-family: $font-family-interface;
    font-size: $font-size-interface-medium;
    font-style: italic;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  &-saving-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $green-10;
    color: $green;
    height: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 1rem;
    gap: 0.25rem;

    font-family: $font-family-interface;
    font-size: $font-size-interface-medium;
    font-style: italic;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  &-menu-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .Menu-actions {
    gap: 7px;
    align-items: center;
  }

  .Menu-section {
    &:first-of-type {
      flex-grow: 1;
    }
  }

  &-details-button {
    margin-left: 1rem;
    color: $dark-blue;
    background-color: $light-gray;
    font-weight: 500;

    .Button-icon {
      filter: $make-svg-dark-gray;
      margin-right: 0.5rem;
    }

    .Button {
      &:hover {
        background: $light-gray-hover;
      }
    }
  }

  &-GenericPopoverContent {
    max-width: 300px;

    &-buttons-container {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      width: 100%;

      .primary-button-small {
        width: 50%;
      }

      .secondary-button-dark-small {
        width: 50%;
      }
    }
  }
}
