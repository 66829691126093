@import 'styles/variables';
$low: $blue;
$mod: $purple;
$high: $red;

svg.MembershipFunctionShapeGraph {
  user-select: none;
  font-size: $font-size-interface-small;
  font-weight: $font-weight-normal;

  path {
    fill: none;
    stroke: black;
    stroke-width: 2px;
  }

  circle {
    stroke-width: 2px;
    stroke: rgba(0, 0, 0, 0);
  }

  circle:hover {
    cursor: pointer;
    stroke-width: 2px;
    stroke: black;
  }

  .HIGH {
    path {
      stroke: $high;
    }

    circle,
    text {
      fill: $high;
    }
  }

  .MOD {
    path {
      stroke: $mod;
    }

    circle,
    text {
      fill: $mod;
    }
  }

  .LOW {
    path {
      stroke: $low;
    }

    circle {
      fill: $low;
    }

    text {
      fill: $low;
    }
  }

  .legend line {
    stroke: $light-gray;
    stroke-dasharray: 5;
    stroke-width: 1px;
    fill: none;
    stroke-width: 1px;
  }

  text {
    font-family: $font-family-interface;
    stroke: none;
    fill: #4a5568;
    font-weight: normal;
  }

  .normalized text {
    font-family: monospace;
  }

  .point text {
    fill-opacity: 0;
  }

  .point:hover text {
    fill-opacity: 1;
  }
}

.NodeSettingsSectionMembershipFunctions {
  .legend {
    font-family: $font-family-interface;
    display: flex;
    font-size: $font-size-interface-small;
    font-weight: $font-weight-semi-bold;
    gap: 1em;
    justify-content: space-between;
  }

  .LOW {
    background-color: $low;
    color: $white;
    padding: 0.25em 0.625em;
  }

  .MOD {
    background-color: $mod;
    color: $white;
    padding: 0.25em 0.625em;
  }

  .HIGH {
    background-color: $high;
    color: $white;
    padding: 0.25em 0.625em;
  }
}
