@import 'styles/variables';

.SelectedConnections {
  background: $off-white;
  padding: 0.5rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-family: $font-family-interface;
  font-weight: normal;
  height: 100%;

  &-scroll-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 0;
    overflow: auto;
  }

  label {
    font-family: $font-family-interface;
    font-size: 0.875rem;
    font-weight: normal;
  }

  .SelectedConnection {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9em;
    background: white;
    border-radius: 0.5rem;
    padding: 0.25rem;
    margin-bottom: 0.5rem;
    justify-content: space-between;
    border: 1px solid transparent;

    &.selected {
      border: 1px solid $dark-blue;
    }

    &-connection {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      max-width: calc(100% - 4rem);
    }

    &-buttons {
      display: flex;
    }

    .strength-value {
      background: $off-white;
      border-radius: 6px;
      padding: 0.25rem;
    }
  }
}
