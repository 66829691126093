@import 'styles/variables';

.FolderListItem {
  display: grid;
  height: 3.25rem;

  &-main {
    display: flex;
    cursor: pointer;

    &.draggable {
      cursor: grab;
    }
  }

  &-background {
    background-color: $off-white;
    align-items: center;
    color: black;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    grid-column-start: 1;
    grid-column-end: 3;
    border-radius: 8px;
    gap: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &:hover,
    &.highlighted {
      background-color: $light-gray;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }

  &-meta {
    display: flex;
    align-items: center;
  }

  &-createdBy {
    margin-right: 1rem;
    font-size: 0.8em;

    &.text-interface-plain {
      color: black;
    }
  }

  &-modifiedDate.text-interface-plain {
    color: $gray-500;
  }

  &-name {
    align-items: baseline;
    display: flex;
  }

  &-matrixName {
    font-family: $font-family-display;
    font-weight: 500;
    font-size: 1.25em;
    padding-left: 0.75em;
  }

  &-image {
    flex-shrink: 0;
    height: 3.25rem;
    width: 4.25rem;
    margin-right: 0.5rem;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
      background-color: white;
    }
  }

  &-matrixDetails {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }

  &-arrow {
    color: $gray-300;
    cursor: pointer;
    margin-right: -0.5rem;
    transition: transform 0.25s;
  }

  .icon-container {
    display: flex;
  }
}
