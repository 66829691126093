@import 'styles/variables';

.ViewOnlyBadge {
  background-color: $dark-gray;
  color: $white;
  border-radius: $button-border-radius;
  padding: 0.25rem 0.35rem;
  font-family: 'Public Sans';
  font-size: $font-size-interface-small;
  font-style: normal;
  font-weight: $font-weight-medium;
}
