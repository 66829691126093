@import 'styles/variables';

.QuickSettingsPopoverContent {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-family: $font-family-interface;

  .SettingsButtonSet {
    .pill-button-unselected {
      background-color: $link-background;
      color: $white;
      &:hover {
        background-color: $dark-blue-hover;
      }
    }

    .pill-button-selected {
      background-color: $white;
      color: $dark-blue;
    }
  }

  .InputField {
    margin-bottom: unset;
  }

  &-row {
    display: flex;
    flex-direction: column;
  }

  &-label {
    font-weight: $font-weight-semi-bold;
    margin-bottom: 0.5rem;
    font-size: $font-size-interface-medium;
  }

  &-button {
    justify-content: start !important;
    border: none !important;
  }
}
