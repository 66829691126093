@import 'styles/variables';

.ConnectionsTable {
  font-size: 0.9em;
  font-family: $font-family-interface;
  width: 100%;
  height: 100%;

  &-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sticky-table {
    width: 100%;
    border-spacing: 0;
  }

  .sticky-table-cell {
    border-bottom: 1px solid $light-gray;
    border-right: 1px solid $light-gray;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    max-height: 2rem;
    height: 2rem;
    min-width: 8rem;
    width: 8rem;
    overflow: hidden;

    input {
      background: none;
      border: none !important;
      outline: none !important;

      width: 100%;
      height: 100%;
      text-align: left;
      padding: 0.5rem;

      &.highlighted {
        background-color: $off-white !important;
      }

      &:hover {
        border: none !important;
        outline: none !important;
      }

      &:focus {
        border: none !important;
        outline: none !important;

        &.zero {
          color: $text-color !important;
        }
      }
    }
  }

  .node-name {
    font-size: 0.8em;
    font-weight: bold;
    background-color: $off-white !important;
    color: $dark-gray;
    flex-grow: 1;
    width: 0;

    .NodePill {
      cursor: default;
    }
  }

  .connection-value {
    position: relative;

    &.selected {
      border: 1px solid black;
      outline: 1px solid black;
    }

    &.negative {
      color: $red;
    }
    &.positive {
      color: $blue;
    }
    &.zero {
      color: transparent;
    }
  }

  .sticky-table {
    color: $dark-gray;
    font-family: $font-family-interface;
    font-size: 0.75rem;
    font-weight: 500;
    width: 100%;
    height: auto;
    max-height: 100%;

    &-table {
      border: 1px solid rgb(229, 229, 229);
    }

    .heading {
      background-color: $off-white !important;
    }

    .name-tooltip {
      display: flex;
      flex-direction: row;
      align-items: center;
      .Tooltip {
        margin: 0 0.5em;
      }
    }

    .sticky-table-cell {
      border-left: 1px solid rgb(229, 229, 229);
      border-right: 1px solid rgb(229, 229, 229);
      text-align: left;
      padding: unset;

      &.highlighted {
        background-color: $off-white !important;

        &.node-name {
          background-color: $light-gray !important;
        }
      }
    }

    .sticky-table-row .sticky-table-cell:first-child {
      border-left: none;
    }

    .sticky-table-row .sticky-table-cell:last-child {
      border-right: none;
    }
  }
}
