@import 'styles/variables';

.ButtonNew {
  &-icon {
    &-right {
      margin-left: 0.5rem;
    }
    &-left {
      margin-right: 0.5rem;
    }
  }
}
