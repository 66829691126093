@import 'styles/variables';

$input-height: 2.5rem;

.Settings {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50vw;
  min-height: 60vh;
  overflow-y: auto;
  padding: 2rem;
  gap: 1rem;

  a {
    color: $green;
    text-decoration: underline;
  }

  &-header {
    display: flex;
    font-family: $font-family-interface;
    font-weight: $font-weight-extra-bold;
    font-size: $font-size-interface-extra-large;
    gap: 0.5rem;
    color: $dark-blue;
  }

  &-menu {
  }

  &-content {
    flex-grow: 1;
  }

  &-footer {
    font-family: $font-family-interface;
    font-weight: $font-weight-normal;
    font-size: $font-size-interface-medium;
    color: $dark-gray;
    margin-top: 1rem;
    bottom: 0;
  }

  &-label {
    font-family: $font-family-interface;
    font-weight: $font-weight-extra-bold;
    font-size: $font-size-interface-small;
    margin-bottom: 0.5rem;
    color: $dark-blue;
  }

  &-description {
    font-family: $font-family-interface;
    font-weight: $font-weight-normal;
    font-size: $font-size-interface-small;
    color: $dark-gray;
    margin-top: 1rem;
  }
}

.SettingsTextField {
  display: flex;
  flex-direction: column;

  &-input {
    margin-bottom: unset;

    input {
      height: $input-height;
    }
  }
}

.SettingsViewOnlyText {
  display: flex;
  flex-direction: column;

  &-text {
    font-family: $font-family-interface;
    font-weight: $font-weight-extra-bold;
    font-size: $font-size-interface-small;
    color: $dark-gray;
    display: flex;
    align-items: center;
    height: $input-height;
  }
}

.SettingsDescription {
  width: 100%;
  display: flex;
  flex-direction: column;

  .InputField {
    margin-bottom: unset;
  }

  &-text-container {
    width: 100%;
  }
}

.SettingsButtonSet {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-container {
    width: 100%;
  }
}

.SettingsNumberField {
  &-input {
    margin-bottom: unset;

    input {
      height: $input-height;
    }
  }
}
