@import 'styles/variables';

.StickySideModal {
  background-color: $modal-background;
  mix-blend-mode: normal;
  z-index: 10000;

  position: fixed;
  overscroll-behavior: contain;
  top: $top-bar-height;
  right: 0;
  bottom: 0;
  left: 0;
  height: calc(100% - $top-bar-height);
  width: 100%;

  &-main {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    height: 100%;
  }

  &-outside {
    display: flex;
    flex-grow: 1;
    height: 100%;
  }

  &-content {
    background-color: $white;
    border-radius: $modal-border-radius 0px 0px $modal-border-radius;
    display: flex;
    height: 100%;
    width: 70vw;
    overflow: hidden;
  }

  .title {
    color: $dark-blue;
    font-family: $font-family-display;
    font-weight: 400;
    font-size: 2em;
  }

  .text-interface-medium {
    color: $dark-blue;
    font-family: $font-family-interface;
    font-weight: 400;
    font-size: 1.125rem;
  }

  .TextArea {
    font-size: 1rem;
  }

  .trash-button {
    margin-left: 0.25em;
  }

  .text-interface-description {
    color: $dark-gray;
    font-family: $font-family-interface;
    font-weight: 400;
    font-size: 1em;
  }
  .text-interface-subtext {
    color: $dark-blue;
    font-size: 0.875rem;
    font-weight: 400;
  }
}
