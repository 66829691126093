@import 'styles/variables';

.NodeSettingsSectionConnections {
  .NewConnection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.75em;
    gap: 0.5em;
    .TextButton {
      padding: 0;
      margin: 0;
      color: $dark-gray;
      font-size: 0.875em;
    }
  }

  .ConnectionEditor {
    .InputField {
      margin-bottom: 0;
    }
    margin-bottom: 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
