@import 'styles/variables';

.ConnectionCell {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  input {
    display: flex;
    flex-grow: 1;
    text-align: left;
  }

  &-button-container {
    display: flex;
    flex-shrink: 0;
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  &-button {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    background-color: $off-white;
    padding: 0.15rem;
    cursor: pointer;
    border: 2px solid white;

    &:hover {
      background-color: $light-gray;
    }

    &.disabled {
      background-color: $light-gray;
    }

    &-icon-up {
      width: 0;
      height: 0;
      border-left: 0.25rem solid transparent;
      border-right: 0.25rem solid transparent;
      border-bottom: 0.25rem solid $dark-blue;

      &.disabled {
        border-bottom: 0.25rem solid $dark-gray !important;
      }
    }

    &-icon-down {
      width: 0;
      height: 0;
      border-left: 0.25rem solid transparent;
      border-right: 0.25rem solid transparent;
      border-top: 0.25rem solid $dark-blue;

      &.disabled {
        border-top: 0.25rem solid $dark-gray;
      }
    }
  }

  .connection-value {
    position: relative;

    &.selected {
      border: 1px solid black;
      outline: 1px solid black;
    }

    &.negative {
      color: $red;
    }
    &.positive {
      color: $blue;
    }
    &.zero {
      color: transparent;
    }
  }
}
