@import 'styles/variables';

.error-list {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  font-family: $font-family-interface;
  font-weight: normal;
  font-size: 0.75em;
  gap: 0.5em;

  .error {
    background: #fdebf0;
    border: 1px solid #eb346c;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    padding: 0.75rem 1rem;

    &-title {
      font-weight: bold;
    }
  }
}
