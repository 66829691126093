@import 'styles/variables';
@import 'styles/fonts';

.ModificationsSidebar {
  &-nodeList {
    list-style: none;
    margin: 0 0 1em;
    padding: 0;
  }
  .NodePill {
    &-name {
      padding-right: 0.25em;
    }
    &:first-of-type {
      margin: 0.25em 0 0;
    }
  }
}

.OverridesListElement {
  display: flex;
  flex-direction: column;
  margin: 0.25em 0 0.25em;
  padding: 0 1.25em 0;
  overflow-x: auto;
  background-color: $off-white;
  border-radius: $button-border-radius;

  &-title {
    display: flex;
    align-items: center;
    // This makes up for the padding of NodePill
    // Easier to make this allowance here than add a prop
    margin-left: -5px;
  }

  &-element {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &-details {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &-overrides {
    display: flex;
    flex-direction: column;
    margin: 0.25rem 0;
    gap: 0.25em;
  }

  &-override-details {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &-override-number {
    margin: 0 0.5rem;
  }

  &-image {
    height: 2rem;
    margin-right: 1rem;
    width: 2rem;

    &-missing,
    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }

    &-missing {
      background-color: $gray-500;
      display: block;
      height: 100%;
    }
  }

  &-new {
    color: $dark-gray;
    font-size: 0.75em;
    margin-left: 1em;
  }

  &.deleted .OverridesListElement-details {
    opacity: 0.5;
  }

  .TextButton {
    font-family: $font-family-interface;
    font-size: $font-size-interface-small;
    color: $dark-blue;
    font-size: 0.75em;
    font-weight: 700;
    filter: $make-svg-dark-blue;
    padding: 0;
    margin: 0.5em 0 0.25em;
  }
}

.OverrideElement {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  font-family: $font-family-interface;
  font-size: $font-size-interface-small;
  color: $dark-gray;
  font-size: 0.625em;

  &-property {
    margin-right: 0.5rem;
    //This attempts to keep containers where the property name wraps small, but creates margins to the right of smaller names
    flex: 0 1 50%;
    font-weight: 700;
  }

  &-breadcrumbs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1 1 100%;
    font-size: 1em;
    gap: 4px;
  }

  &-description {
    display: flex;
    flex-direction: column;
  }

  &-actions {
    margin-left: 0.25rem;
    flex: 0 0 12px;
    .Icon {
      height: 12px;
      width: 12px;
    }
  }

  &-arrow {
    margin: 0;
    font-weight: normal;
  }

  &-from {
    font-weight: normal;
  }
  &-to {
    font-weight: bold;
  }

  &-units {
    font-weight: normal;
  }

  &-fromToArrow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
}
