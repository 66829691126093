@import 'styles/variables';

.ConnectionsView {
  background-color: $white;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;

  &-descriptor-container {
    font-size: $font-family-interface;
    font-size: $font-size-interface-medium;
    color: $dark-gray;
    font-style: italic;
    flex-grow: 1;
  }

  &-header {
    font-family: $font-family-interface;
    font-size: $font-size-interface-extra-large;
    font-weight: $font-weight-extra-bold;
    margin-bottom: 1rem;
    color: $dark-blue;
  }

  &-description {
    margin-bottom: 1rem;
    color: $dark-gray !important;
  }

  &-menu {
    margin-right: 2rem;
  }

  &-content {
    display: flex;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
  }

  &-button-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  &-actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .Button {
      background: none;
      color: $dark-gray;
      padding-left: 0;
      padding-right: 0;

      &:hover {
        background: none;
        filter: $make-svg-dark-blue;
      }
    }
  }
}
