@import 'styles/variables';

.TextArea {
  border: 1px solid $light-gray;
  border-radius: $button-border-radius;
  padding: 0.5rem;
  width: 100%;

  &:focus {
    outline-color: $dark-gray;
  }
}
