@import 'styles/variables';

.MatrixView {
  display: flex;
  flex-grow: 1;
  width: 100%;

  &-Content {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $off-white;
    flex-grow: 1;
  }

  &-Container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 0;
    border-top: 1px solid $light-gray;
  }

  &-Main {
    overflow: auto;
    flex-grow: 1;
  }

  &-expanded {
    flex-grow: 1;
  }

  &-Overflow {
    height: 100%;
  }

  &-details {
    padding: 1rem;
  }

  &-spinner-container {
    display: flex;
    width: 100%;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
}
