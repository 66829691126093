@import 'styles/variables';

.OutputToInputWarningModal {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &-text {
    text-align: left;
    width: 100%;
    font-size: $font-size-interface-medium;
    font-family: $font-family-interface;
    font-weight: $font-weight-normal;
    line-height: $font-size-interface-large;
    max-width: 500px;
  }

  &-checkbox-container {
    text-align: left;
    width: 100%;
    font-size: $font-size-interface-medium;
    font-family: $font-family-interface;
    font-weight: $font-weight-semi-bold;
    line-height: $font-size-interface-large;
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &-checkbox {
    height: $font-size-interface-medium;
    width: $font-size-interface-medium;
    border: 1px solid $white;
    border-radius: $checkbox-border-radius;
    cursor: pointer;
  }

  &-buttons-container {
    display: flex;
    width: 100%;
    gap: 1rem;
  }

  &-button-container {
    width: 50%;
  }

  &-button {
    width: 100%;
  }
}
