@import 'styles/variables';

.MatrixSettingsModal {
  &-title-container {
    margin-bottom: 1rem;
    cursor: pointer;
  }

  &-advanced {
    &-title {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      cursor: pointer;
    }
  }

  &-settings-title {
    display: flex;
    align-items: center;
    font-family: $font-family-interface;
    font-size: $font-size-interface-medium;
    color: $dark-gray;
  }

  &-description {
    margin-bottom: 1rem;
  }

  &-column {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 0.5rem;

    &-description-offset {
      margin-top: 1.5rem;
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  &-fields {
    gap: 40px;
  }

  &-fields-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
    gap: 0.8em;
    flex: 1;
  }

  .Modal-content {
    height: 75vh;
  }

  &-actions {
    align-items: center;
    border-top: 1px solid $gray-300;
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-top: 2rem;
    padding-top: 2rem;

    span {
      margin: 0 1rem;
    }
  }

  &-menu-title {
    word-break: break-word;
  }

  &-menu-image {
    &,
    &-missing {
      border-radius: 5px;
      margin: 1rem 0;
      width: 100%;
      max-height: 8rem;
      object-fit: cover;
    }

    &-missing {
      background-color: $gray-500;
      height: 100px;
    }
  }
}
