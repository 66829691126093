@import 'styles/variables';

.MatrixSettings {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &-row {
    display: flex;
    gap: 1rem;
    width: 100%;
  }

  &-number-input-row {
    display: flex;
    gap: 1rem;
    width: 100%;
    .SettingsNumberField {
      max-width: 100px;
    }
  }

  &-column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-half {
    width: 50%;
  }

  &-grow {
    flex-grow: 1;
  }

  &-shrink {
    min-width: 30%;
    flex-shrink: 1;
  }

  &-DeletePopoverContent {
    max-width: 300px;

    &-buttons-container {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      width: 100%;

      .destructive-button-small {
        width: 50%;
      }

      .secondary-button-dark-small {
        width: 50%;
      }
    }
  }
}
