@import 'styles/variables';

.ImageField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-description {
    font-family: $font-family-interface;
    font-weight: $font-weight-normal;
    font-size: $font-size-interface-small;
    color: $dark-gray;
    margin-top: 0.5rem;
  }

  &-button {
    margin-top: 0.5rem;
    border: unset !important;
  }

  &-imageContainer {
    position: relative;
    display: flex;
    flex-grow: 1;
    width: 100%;
    max-width: 315px;
    max-height: 210px;
  }

  &-image,
  &-image-missing {
    border-radius: 5px;
    width: 315px;
    height: 210px;
    object-fit: cover;
    margin-bottom: 0.5rem;
  }

  &-image-missing {
    background-color: $gray-500;
    width: 315px;
    height: 210px;
  }

  .Icon {
    max-height: 1rem;
    max-width: 1rem;
  }

  &-label {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 10px;
    gap: 10px;
    cursor: pointer;
    font-family: $font-family-interface;
    font-size: 0.75em;
    background-color: $dark-blue;
    color: $off-white;
    border-radius: 6px;
    box-shadow: 0px 0px 3px rgba(167, 167, 167, 0.25);

    &-icon {
      filter: $make-svg-off-white;
    }
  }

  &-input {
    opacity: 0;
    height: 0.1px;
    width: 0.1px;
  }

  &[disabled] {
    .ImageField-label {
      color: lightgray;
      cursor: not-allowed;
    }
  }
}
