@import 'styles/variables';

.ConnectionEditor {
  &-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  &-button-container {
    margin-top: 0.5rem;
  }
}

.ConnectionField {
  display: flex;
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  &-otherNode {
    flex-grow: 1;

    .Dropdown > * {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &-influence {
    align-items: center;
    display: flex;
    border: 1px solid $gray-300;
    border-left: none;
    flex-shrink: 0;
    padding: 0 1rem;
    width: 7rem;
    gap: 0.5rem;

    .TextField {
      background: transparent;
      border: none;
      padding: 0;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    &.negative {
      background: rgba($red, 0.15);
    }

    &.positive {
      background: rgba($blue, 0.15);
    }
  }

  &-delete {
    .Button {
      background: transparent;
      border: 1px solid $gray-300;
      border-left: none;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      color: $red;
      height: 100%;
      padding: 0 1rem;
    }
  }
}
