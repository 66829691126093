@import 'styles/variables';

.SettingsModal {
  &-menu {
    margin-top: 0.5em;
  }

  &-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
