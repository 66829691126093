@import 'styles/variables';

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.Spinner {
  height: 100%;
  max-height: 90vh;
  position: relative;

  &::before {
    animation: 2s linear infinite spinner;
    border: solid 5px $light-gray;
    border-bottom-color: $dark-blue;
    border-radius: 50%;
    content: '';
    height: 40px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 40px;
    will-change: transform;
  }
}
