@import 'styles/variables';

.NodesView {
  display: flex;
  flex-grow: 1;
  background-color: $white;
  height: 100%;
  position: relative;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;

    &-title {
      font-family: $font-family-interface;
      font-weight: $font-weight-extra-bold;
      font-size: $font-size-interface-medium-large;
      color: $dark-blue;
    }

    &-button-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &-content {
    flex-grow: 1;
    padding: 1rem;
    height: 100%;
  }

  &-content-cover {
    flex-grow: 1;
    height: 100%;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 103;
  }

  &-closed-icon-container {
    cursor: pointer;
    padding: 1rem;
  }
}

.TrophicLevelMeta {
  display: flex;
  align-items: center;
  margin: 10px 0 4px;
  font-family: 'Public Sans', sans-serif;
}

.TrophicLevelLabel {
  margin: 0;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  color: $dark-blue;
  font-size: $font-size-interface-small;
  font-family: $font-family-interface;
  font-weight: $font-weight-extra-bold;
}

.TrophicLevelCount,
.TrophicLevelNewNode {
  display: inline-block;
  font-size: 15px;
  color: #999999;
  margin-left: 10px;
  font-weight: 600;
}

.TrophicLevelNewNode {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.NewTrophicLevelNode {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 15px;
  color: #999999;
  font-weight: 600;
  margin-top: 8px;
}

.TrophicLevelLabelIcon {
  display: block;
  margin-right: 4px;
  filter: $make-svg-dark-blue;
}

.TrophicLevelNodes {
  background-color: #f4f7f7;
  text-align: center;
}
