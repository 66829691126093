@import 'styles/variables';

.DensitiesContent {
  display: flex;
  flex-direction: column;

  &-row {
    display: flex;
    gap: 1rem;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    .InputField {
      margin-bottom: 0;
    }
  }
  .activation-function {
    width: 100%;
    display: flex;
    margin-top: 1em;
    gap: 1em;

    .text-interface-subtext {
      margin-top: 0.25em;
    }
    .text-interface-description {
      font-size: 0.75em;
    }
  }
}

.NodeSettingsSectionMembershipFunctions {
  width: 100%;
}

.DensitiesInput {
  .InputField-input {
    height: 2rem;
    input {
      height: 100%;
    }
  }
}
