@import 'styles/variables';

.AbundanceView {
  background-color: $white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &-content {
    width: 100%;
    height: 0;
    flex-grow: 1;
  }

  &-header-container {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  &-menu {
    margin-right: 2rem;
  }

  .NodePill {
    font-size: inherit;
  }

  marker {
    fill: none;
    &#arrow_left {
      stroke: $red;
    }
    &#arrow_right {
      stroke: $blue;
    }
  }

  .sticky-table {
    color: $dark-gray;
    font-family: $font-family-interface;
    font-size: 0.75rem;
    font-weight: 500;
    width: 100%;
    height: auto;
    max-height: 100%;

    &-table {
      border: 1px solid rgb(229, 229, 229);
    }

    .heading {
      background-color: $off-white !important;
    }

    .name-tooltip {
      display: flex;
      flex-direction: row;
      align-items: center;

      .Tooltip {
        margin: 0 0.5em;
      }
    }

    .sticky-table-cell {
      border-left: 1px solid rgb(229, 229, 229);
      border-right: 1px solid rgb(229, 229, 229);

      background-color: rgb(255, 255, 255);
      text-align: left;
    }

    .sticky-table-row .sticky-table-cell:first-child {
      border-left: none;
    }

    .sticky-table-row .sticky-table-cell:last-child {
      border-right: none;
    }

    .sticky-table-row {
      &.decrease,
      &.node-deleted {
        color: $red;
        stroke: $red;
      }
      &.increase,
      &.node-added {
        color: $blue;
        stroke: $blue;
      }

      .sticky-table-cell.original-abundance {
        color: $dark-gray;
      }

      .sticky-table-cell.modified-abundance {
        &.modIncrease {
          color: $blue;
        }
        &.modDecrease {
          color: $red;
        }
      }

      .sticky-table-cell.status {
        color: $dark-gray;

        .fixed {
          color: $green;
          background-color: $green-10;
          border-radius: 1rem;
          padding: 0.5rem;
          display: inline-flex;
        }
      }

      .sticky-table-cell.units {
        color: $dark-gray;
        padding-right: 0.5em;
      }

      .sticky-table-cell.name {
        width: 10%;
        stroke: none;
        padding-top: 0.75rem;
        padding-bottom: 1px;
      }
      .sticky-table-cell.change-abundance {
        stroke-width: 1.2px;
        vertical-align: middle;

        &:not(.heading) {
          padding: 0.5rem 0rem !important;
        }

        svg {
          background: $off-white;
          cursor: default;
          width: 100%;
          // position: relative;
          top: 100%;

          .no-change-dot {
            stroke: none;
          }
        }
      }

      .sticky-table-cell.fuzzy-change {
        text-transform: capitalize;
      }

      .sticky-table-cell.not-significant {
        color: $dark-gray;
      }
    }
  }
}

.AbundanceTable {
  height: 100%;
}

// .AbundanceArrow {
//   &-svg-wrap {
//     margin-left: 4px;
//     margin-right: 4px;
//   }
// }
