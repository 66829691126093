@import 'styles/variables';

.Button {
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  background: $dark-blue;
  color: $white;
  border-radius: $button-border-radius;
  padding: 0.5rem 1rem;
  font-family: $font-family-interface;
  font-weight: 400;

  &:disabled {
    background: $light-gray;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background: $dark-blue-hover;
    color: $white;
  }

  &.hasLabel.hasIcon {
    justify-content: space-between;
  }

  &-img,
  &-icon {
    max-height: 1.75rem;
    max-width: 1.75rem;
  }

  &-icon-left {
    margin-right: 0.5rem;
  }

  // When both label and icon are present, ensure some spacing between the two
  &-label + &-icon,
  &-label + &-img {
    margin-left: 0.5rem;
  }

  &[disabled='false']:hover {
    background: $green-hover;
  }

  &.invert {
    background: none;
    border: 1px solid $dark-blue;
    color: $dark-blue;
    &:hover {
      background: $light-gray-hover;
    }
  }

  &.white-button {
    background: none;
    border: 1px solid $light-gray;
    box-shadow: 0px 0px 3px rgba(167, 167, 167, 0.25);
    color: $dark-blue;
    border-radius: 6px;
    padding: 0.375rem 0.75rem;
    font-size: 0.875em;
    font-weight: 500;
    gap: 10px;
    &:hover {
      background: $light-gray-hover;
    }
  }

  &.back-arrow {
    background: none;
    border: none;
    color: $dark-blue;
    &:hover {
      background: none;
      filter: $make-svg-dark-gray;
    }
  }

  // -----------------------------------

  &.primary {
    background: $green !important;
    border: none !important;
    color: $off-white !important;
    &:hover {
      background: $green-hover !important;
    }

    &:disabled {
      background: $light-gray !important;
      color: $dark-gray !important;
    }
  }

  &.secondary {
    background: $white !important;
    border: 1px solid $light-gray !important;
    color: $dark-blue !important;
    &:hover {
      border: 1px solid $dark-blue !important;
    }

    &:disabled {
      color: $dark-gray !important;
    }
  }

  &.tertiary {
    background: none !important;
    border: none !important;
    color: $green !important;
    &:hover {
      color: $green-hover !important;
    }

    &:disabled {
      color: $dark-gray !important;
    }
  }

  // -----------------------------------

  &.pill-button {
    text-align: center;
    background: $off-white;

    color: $dark-blue;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 20px;
    border: 1px solid $off-white;

    &.invert {
      background: none;
      border: 1px solid $off-white;
      color: $off-white;
      &:hover {
        background: $light-gray-hover;
      }
    }

    &.selected {
      background: $dark-blue;
      color: $off-white;
    }

    &:not(.selected):hover {
      color: $dark-blue;
      background: $off-white;
      border: 1px solid $dark-blue;
    }

    &:last-of-type {
      &:after {
        content: '';
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.new-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: none;
    color: $dark-gray;
    border-radius: 5px;
    padding: 0px 8px;
    gap: 7px;

    font-size: 0.875em;
    font-weight: 500;

    &:hover {
      background: $light-gray;
    }

    .Icon {
      height: 10px;
      filter: $make-svg-dark-gray;
    }
  }

  &.settings {
    align-items: center;
    background: none;
    height: 1.25em;
    width: 1.25em;
    padding: 13px;
    &:hover {
      background: $light-gray;
    }
  }

  &.ShareButton {
    margin: 0;
    font-size: 0.875rem;
  }

  &.action {
    margin: 0;
    font-size: 0.875rem;
  }

  &.reset {
    background: none;
    border: 1px solid $light-gray;
    border-radius: 6px;
    padding: 4px;

    .Icon {
      width: 14px;
    }

    box-shadow: 0px 0px 3px rgba(167, 167, 167, 0.25);
    &:hover {
      background: $light-gray;
    }
  }

  &.large {
    font-size: 20px;
    box-shadow: 0px 0px 3px rgba(167, 167, 167, 0.25);
  }

  &.medium {
    font-size: 0.875rem;
    padding: 6px 10px;
    border-radius: 0.5rem;
    font-weight: 500;
  }

  &.modifications-button {
    vertical-align: middle;
    margin: 0.5em 0;
    background: none;
    border: 1px solid $light-gray;
    box-shadow: 0px 0px 3px rgba(167, 167, 167, 0.25);
    border-radius: 6px;
    color: $text-color;
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 1rem;
    padding: 2px 6px;
    gap: 4px;
    .Icon {
      filter: $make-svg-dark-blue;
      width: 10px;
      height: 10px;
    }
  }
  &.small,
  &.tiny {
    background: none;
    border: 1px solid $light-gray;
    border-radius: 5px;
    color: $text-color;
    font-size: $font-size-interface-small;
    padding: 0.25rem;

    .Icon {
      width: 16px;
    }
  }

  &.tiny {
    border: none;
    padding: 0;
  }

  &.destruct {
    background: none;
    border: 1px solid $red;
    color: $text-color;
    &:hover {
      background: $red;
      color: $white;
    }
  }
}
