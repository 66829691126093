@import 'styles/variables';

.TourContentComponent {
  strong {
    font-size: 1em;
    font-weight: 600;
  }
  &-content {
    margin-top: 0.5em;
    color: $light-gray;
    font-size: 0.875em;
  }
}

.Popover-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 0.75em;

  color: $white;
  font-family: $font-family-interface;
  font-size: 0.875em;
  font-weight: 500;

  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: 8px;
  }

  button {
    padding: 6px 12px;
    border-radius: 10px;

    &.next {
      &:not(.selected):hover {
        background-color: $light-gray;
        border-color: $off-white;
      }
    }
    &.prev {
      &:not(.selected):hover {
        color: $off-white;
        background-color: $dark-blue-hover;
        border-color: $off-white;
      }
    }

    cursor: pointer;
    .disabled {
      cursor: not-allowed;
    }
  }
}
