@import 'styles/variables';

.BuildMatrixModal {
  padding: 7rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-head {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .InputField {
    width: 15rem;
  }
}

// -------------------------------------------------
.BuildMatrixIntro {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
  }

  &-arrows {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-body {
    flex-grow: 1;
    width: 100%;
    background-color: $light-gray;
    border-radius: $modal-border-radius;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    height: 0;
  }

  &-image {
    height: 70%;
    width: auto;
  }

  &-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

// -------------------------------------------------
.BuildMatrixCreateMatrix {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-tabs {
    width: 100%;
    display: flex;
    font-family: $font-family-interface;
    font-weight: 500;
  }

  &-tab {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    border: 2px solid $off-white;
    border-right: 0px;
    background-color: white;
    color: $dark-blue;
  }

  &-tab:last-of-type {
    border-right: 2px solid $off-white;
  }

  &-body {
    flex-grow: 1;
    width: 100%;
  }

  .invert {
    background-color: $dark-blue;
    color: white;
  }

  .number-label {
    display: flex;
    height: 1rem;
    width: 1rem;
    padding: 0.75rem;
    border-radius: 50%;
    background-color: $dark-blue;
    color: white;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
  }

  .invert .number-label {
    background-color: white;
    color: $dark-blue;
  }
}

// -------------------------------------------------

.BuildMatrixFooterButtons {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  justify-content: space-between;

  .sequential-buttons-container {
    display: flex;
    gap: 0.5rem;
  }

  .back-button {
    background: none;
    border: none;
    margin-right: 1rem;
  }
}

// -------------------------------------------------

.BuildMatrixAddNodes {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $off-white;

  &-column {
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;

    .header {
      margin-bottom: 12px;
    }

    .description {
      margin-bottom: 12px;
      color: $dark-gray;
    }

    .text-container {
      display: flex;
      width: 100%;
      align-items: center;
      height: 2rem;

      .TextField {
        height: 100%;
        .InputField {
          height: 100%;
          &-input {
            height: 100%;
          }
          input {
            height: 100%;
          }
        }
      }
    }

    .submit-button {
      height: calc(100% - 2rem);
      background: none;
    }
    .submit-button:hover {
      background: none;
    }

    .text-input {
      flex-grow: 1;
      margin-bottom: unset;
    }

    .node-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 3rem;
      margin-left: 3rem;
    }

    .node {
      width: 100%;
      background-color: $light-gray;
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 2rem;
      padding-left: 2rem;
    }
  }
}

// -------------------------------------------------
.BuildMatrixEditNodes {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $off-white;

  &-body {
    height: 80%;
    width: 100%;
    margin-bottom: 4rem;
  }
}

// -------------------------------------------------

.BuildMatrixEditNodesProgressLine {
  width: 15rem;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  &-tooltip {
    position: fixed;
  }

  .step {
    cursor: pointer;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    background-color: $dark-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    &.selected {
      background-color: $dark-blue;
    }
  }
}

// -------------------------------------------------

.BuildMatrixEditNodesContent {
  width: calc(100% - 12rem);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 6rem;
  margin-right: 6rem;

  .column {
    display: flex;
    flex-direction: column;
    width: 45%;
    height: 100%;
    justify-content: center;
  }

  .header {
    margin-bottom: 1rem;
  }

  .description {
    color: $dark-gray;
    margin-bottom: 1rem;
    line-height: 160%;
  }

  .InputField {
    width: 100%;
  }
}

// -------------------------------------------------

.BuildMatrixEditNodesBasicSettings {
  .BuiltNode {
    width: 100%;
    height: 80%;
    background-color: white;
    border-radius: 1rem;

    &-header {
      background: $light-gray;
      padding: 1rem;
      border-radius: 1rem 1rem 0 0;
    }

    &-photo {
      width: 10rem;
      height: auto;
      margin-left: 2rem;
      margin-top: 2rem;
      margin-right: 1rem;
    }

    &-container {
      display: flex;
      margin-bottom: 1rem;
      align-items: flex-end;
    }

    &-image-container {
      margin-left: 20px;
      margin-top: 20px;
      flex-shrink: 0;
      height: 4rem;
      margin-right: 0.5rem;
      width: 6rem;
    }

    &-image-container img {
      height: 100%;
      object-fit: cover;
      width: 100%;
      background-color: #e2e8f0;
    }

    &-info {
      color: $dark-gray;
    }

    &-badge-container {
      display: flex;
      margin: 20px;
    }

    &-button {
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      cursor: pointer;
      width: fit-content;
      background-color: $light-gray;
      border-radius: 4px;
    }
  }
}
// -------------------------------------------------

.EditTrophicLevel {
  &-button {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    width: fit-content;
    margin-right: 0.5rem;
  }
}

// -------------------------------------------------

.BuildMatrixEditNodesConnections {
  .ConnectionEditor {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ConnectionEditor-body {
    width: 100%;
    padding: 1rem;
    background-color: white;
    flex-grow: 1;
    border-radius: 1rem;
    overflow: auto;
  }

  .InputField {
    width: 100%;
  }

  .ConnectionSingleValue {
    font-size: 1rem;
  }
}

// -------------------------------------------------

.BuildMatrixEditNodesPopulationDensities {
  .EditActivationFunction {
    .activation-function {
      .text-interface-description {
        display: none;
      }
    }
  }

  .EditAbundanceRange {
    .InputField-label {
      font-size: $font-size-interface-medium;
      font-weight: 500;
    }
  }
}

// -------------------------------------------------

.BuildMatrixReview {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $off-white;

  &-container {
    margin-left: 6rem;
    margin-right: 6rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .BuiltNode {
    width: 100%;
    height: 80%;
    background-color: white;
    border-radius: 1rem;

    .AbundanceSlider {
      .InputField-label {
        display: none;
      }
    }

    &-abundance-container {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    &-chart-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .NodeSettingsSectionMembershipFunctions {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }

    &-column-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
    }

    &-column {
      display: flex;
      flex-direction: column;
      width: 45%;
    }

    &-header {
      background: $light-gray;
      padding: 1rem;
      border-radius: 1rem 1rem 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-photo {
      width: 10rem;
      height: auto;
      margin-left: 2rem;
      margin-top: 2rem;
      margin-right: 1rem;
    }

    &-container {
      display: flex;
      margin-bottom: 1rem;
      align-items: flex-end;
    }

    &-image-container {
      margin-left: 20px;
      margin-top: 20px;
      flex-shrink: 0;
      height: 6rem;
      margin-right: 0.5rem;
      width: 6rem;
    }

    &-image-container img {
      height: 100%;
      object-fit: cover;
      width: 100%;
      background-color: #e2e8f0;
    }

    &-info {
      color: $dark-gray;
    }

    &-badge-container {
      display: flex;
      margin: 20px;
    }

    &-button {
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      cursor: pointer;
      width: fit-content;
      background-color: $light-gray;
      border-radius: 4px;
    }

    &-button-container {
      display: flex;
      align-items: center;
    }

    &-name-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &-edit-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }

    &-arrow-button {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background-color: $dark-blue;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.5rem;
      cursor: pointer;
    }

    &-button-disabled {
      background-color: $dark-blue-hover;
      cursor: default;
      pointer-events: none;
    }
  }

  .ReadOnlyConnections {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &-description {
      margin-bottom: 1rem;
    }

    &-section {
      height: calc(50% - 4rem);
      width: calc(100% - 2rem);
      background: $off-white;
      border-radius: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding: 1rem;
    }

    &-connection-container {
      width: calc(100% - 1rem);
      background: white;
      border-radius: 1rem;
      margin-bottom: 0.5rem;
      display: flex;
      padding: 0.5rem;
      align-items: center;
    }

    &-connection-section {
      margin-right: 0.5rem;
    }

    &-value {
      background-color: $off-white;
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
    }

    &-positive {
      color: $blue;
    }
    &-negative {
      color: $red;
    }
  }
}
